import React from "react";
import Dropdown from "react-dropdown";

const options = [
  { value: "1", label: "1 Per Page" },
  { value: "2", label: "2 Per Page" },
  { value: "3", label: "3 Per Page" },
];

const options2 = [
  { value: "default", label: "Default" },
  { value: "pAsc", label: "Published ascending" },
  { value: "pDesc", label: "Published descendng" },
  { value: "rAsc", label: "Reduced ascending" },
  { value: "rDesc", label: "Reduced descendng" },
];

function SortBuy({ handleChangeNbrPerPage, handleChangeSortType }) {
  return (
    <div className="group-select">
      {/* <Dropdown
        options={options}
        className="react-dropdown sort-buy"
        value={options[0]}
        onChange={(e) => handleChangeNbrPerPage(e.value)}
      /> */}
      <Dropdown
        options={options2}
        className="react-dropdown sort-buy"
        value={options2[0]}
        onChange={(e) => handleChangeSortType(e.value)}
      />
    </div>
  );
}

export default SortBuy;
