import React, { useEffect, useRef, useState, useContext } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { getAllFaqs } from "../../services/FaqServices";
import "react-quill/dist/quill.snow.css";

function FaqItem({ data }) {

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const retrieveFaqs = async () => {
      let data = await getAllFaqs();
      let arr = Object.values(data);
      setFaqs(arr);
    }
    retrieveFaqs();
  }, []);

  return (
    <section className="FAQ-section">
      <div className="tf-container st2">
        <div className="row">
          <div className="col-lg-12">
            <div className="accordion-page">
              <h4>Frequently Asked Questions</h4>
              <p className="des">
                Find here the answer to all frequelty asked question about Media Intelligence
              </p>
              {/* <h6>Payments</h6> */}
              <div className="flat-accordion">
                {faqs.map((item) => (
                  <Accordion
                    key={item.id}
                    title={item.question_en}
                    //show={item.answer_en}
                    className="flat-toggle"
                  >
                    <div className="toggle-content">
                      {/* <p>{item.answer_en}</p> */}
                      <div className="ql-snow">
                        <div
                          className="ql-editor"
                          dangerouslySetInnerHTML={{
                            __html: item.answer_en,
                          }}
                        />
                      </div>
                    </div>
                  </Accordion>
                ))}
              </div>

              {/*               <h6>Suggestion</h6>
              <div className="flat-accordion">
                {data.slice(4, 8).map((item) => (
                  <Accordion
                    key={item.id}
                    title={item.title}
                    show={item.show}
                    className="flat-toggle"
                  >
                    <div className="toggle-content">
                      <p>{item.content}</p>
                    </div>
                  </Accordion>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqItem;
