export const buildCategoryTree = (categories) => {
  // Helper object to organize categories by their ID
  const categoryMap = {};
  categories.forEach((category) => {
    categoryMap[category.id] = { ...category, children: [] };
  });

  // Building the tree structure
  const categoryTree = [];
  categories.forEach((category) => {
    if (category.parent_id) {
      // If the category has a parent_id, add it as a child to the corresponding parent category
      categoryMap[category.parent_id]?.children.push(categoryMap[category.id]);
    } else {
      // If there is no parent_id, it's a root category
      categoryTree.push(categoryMap[category.id]);
    }
  });
  return categoryTree;
};

// Recursive function to find a category by its ID, including searching through children
export function findCategoryById(categoryId, categories) {
  const id = Number(categoryId);
  for (const category of categories) {
    if (category.id === id) {
      return category; // Category found
    }
    // Recursively search in children
    const foundInChildren = findCategoryById(categoryId, category.children);
    if (foundInChildren) return foundInChildren; // If found in children, return the category
  }
  return null; // Category not found
}

// Function to build the path from a given category ID to the root
export function buildCategoryPath(categoryId, categories) {
  const category = findCategoryById(categoryId, categories); // Find the category with the given ID

  const path = [];

  let currentCategory = category;
  while (currentCategory) {
    // Add the current category at the beginning of the path
    path.unshift({
      id: currentCategory.id,
      title: currentCategory.name,
      path: `/store?categoryId=${currentCategory.id}`,
    });

    // Find the parent category, if there is one
    if (currentCategory.parent_id && currentCategory.parent_id !== 0) {
      currentCategory = findCategoryById(currentCategory.parent_id, categories);
    } else {
      break; // No more parent category, exit the loop
    }
  }
  path.unshift({
    id: -1,
    title: "Store",
    path: "/store",
  });
  return path; // Return the constructed path
}

// Function to find all category IDs including the specified category and its subcategories recursively
export function findAllCategoryIds(categoryId, categories) {
  // Helper function to recursively collect IDs of a category and its children
  const collectIds = (category, ids = []) => {
    ids.push(category.id); // Add the current category's ID to the list
    // Recursively call for each child category to collect its ID and the IDs of any subcategories
    category.children.forEach((child) => collectIds(child, ids));
    return ids;
  };

  // Find the starting category based on the provided categoryId
  const startingCategory = findCategoryById(categoryId, categories);
  if (!startingCategory) return []; // If the starting category doesn't exist, return an empty array

  // Collect and return IDs starting from the found starting category
  return collectIds(startingCategory);
}
