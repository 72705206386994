import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { img_url } from "../../constants";
import SortBuy from "../dropdown/SortBuy";
import "./style.css";
ArticlesListView.propTypes = {};
const defaultNbrPerPage = 1;
const defaultActualPage = 1;
const defaultSortType = "default";
const LARGE_PAGE_JUMP = 5;
function ArticlesListView(props) {
  const { articles } = props;
  const { className } = props;
  const { handlePopup } = props;

  const [nbrPerPage, setNbrPerPage] = useState(defaultNbrPerPage);
  const [actualPage, setActualPage] = useState(defaultActualPage);
  const [maxPage, setMaxPage] = useState(defaultActualPage);

  const [sortType, setSortType] = useState(defaultSortType);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [paginatedFilteredArticles, setPaginatedFilteredArticles] = useState(
    []
  );
  const [indexOfFirstArticle, setIndexOfFirstArticle] = useState();
  const [indexOfLastArticle, setIndexOfLastArticle] = useState();

  useEffect(() => {
    filterAndSortArticles();
    applyPagination();
  }, [articles, nbrPerPage, sortType]);

  const filterAndSortArticles = () => {
    let sortedArticles = sortArticles(articles, sortType);
    setFilteredArticles(sortedArticles);
  };

  function limitWords(text, limit = 40) {
    if (text === null || text === undefined) return "";

    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + " [...]";
    } else {
      return text;
    }
  }

  const applyPagination = () => {
    setMaxPage(Math.ceil(filteredArticles.length / nbrPerPage));
    const idOfLastArticle = actualPage * nbrPerPage;
    const idOfFirstArticle = indexOfLastArticle - nbrPerPage;
    const currentArticles = filteredArticles.slice(
      idOfFirstArticle,
      idOfLastArticle
    );
    setPaginatedFilteredArticles(currentArticles);
    setIndexOfFirstArticle(idOfFirstArticle);
    setIndexOfLastArticle(idOfLastArticle);
  };

  const nextPage = () => {
    setActualPage((prev) => (prev < maxPage ? prev + 1 : prev));
  };

  const previousPage = () => {
    setActualPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const navigateLargeForward = () => {
    setActualPage((prev) => Math.min(prev + LARGE_PAGE_JUMP, maxPage));
  };

  const navigateLargeBackward = () => {
    setActualPage((prev) => Math.max(prev - LARGE_PAGE_JUMP, 1));
  };

  const navigateToFirstPage = () => {
    setActualPage(1);
  };

  const navigateToLastPage = () => {
    setActualPage(maxPage);
  };

  const handleChangeNbrPerPage = (newValue) => {
    setNbrPerPage(parseInt(newValue));
  };
  const handleChangeSortType = (newValue) => {
    setSortType(newValue);
  };

  function sortArticles(toSortArticles, option) {
    switch (option) {
      case "default":
        return toSortArticles.sort((a, b) => a.id - b.id);
      case "pAsc":
        return toSortArticles.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      case "pDesc":
        return toSortArticles.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      case "rAsc":
        return toSortArticles.sort(
          (a, b) => a.reductionAmount - b.reductionAmount
        );
      case "rDesc":
        return toSortArticles.sort(
          (a, b) => b.reductionAmount - a.reductionAmount
        );
      default:
        return toSortArticles;
    }
  }

  return (
    <section className={`inner-jobs-section ${className}`}>
      <div className="tf-container">
        <div className="row">
          <Tabs className="col-lg-12 tf-tab">
            <div className="wd-meta-select-job">
              <div className="wd-findjob-filer">
                <div className="group-select-display">
                  <Link className="button-filter st2" onClick={handlePopup}>
                    <i className="icon-filter"></i> Filters
                  </Link>
                  <TabList className="inner menu-tab">
                    <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
                          fill="white"
                        />
                      </svg>
                    </Tab>
                    <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
                          fill="#A0A0A0"
                        />
                      </svg>
                    </Tab>
                  </TabList>

                  <p className="nofi-job">
                    <span>{filteredArticles.length}</span> articles found
                  </p>
                  {/* {filteredArticles !== null &&
                    filteredArticles !== undefined && (
                      <p className="nofi-job">
                        Showing <span>{indexOfFirstArticle + 1}</span> to
                        <span>
                          {Math.min(
                            indexOfLastArticle,
                            filteredArticles.length
                          )}
                        </span>{" "}
                        of <span>{filteredArticles.length}</span>
                        articles
                      </p>
                    )} */}
                </div>
                <SortBuy
                  handleChangeNbrPerPage={handleChangeNbrPerPage}
                  handleChangeSortType={handleChangeSortType}
                />
              </div>
            </div>
            <div className="content-tab">
              <TabPanel className="inner">
                <div className="group-col-3">
                  {filteredArticles.map((article) => (
                    <>
                      <div key={article.id} className="features-job cl3">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <Link to={`/store?id=${article.id}`}>
                              <div className="logo-company">
                                <img
                                  src={img_url + article.medias[0].path}
                                  alt="MII"
                                />
                              </div>
                            </Link>
                            <div className="box-content">
                              <h4>
                                <Link
                                  to={`/store?categoryId=${article.category_id}`}
                                >
                                  {article?.category_name}
                                </Link>
                              </h4>
                              <h3>
                                <Link to={`/store?id=${article.id}`}>
                                  {article.name}
                                </Link>
                                {/* <span className="icon-bolt"></span> */}
                              </h3>
                              <ul>
                                {/* <span className="icon-heart"></span> */}
                                <li>
                                  <span className="icon-calendar"></span>
                                  {moment(article.created_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <Link to={`/store?id=${article.id}`}>
                              <p>{limitWords(article.short_description)}</p>
                            </Link>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>

                              {article.hasReduction ? (
                                <>
                                  <p className="price-first alt-price bold">
                                    ${article.price}
                                  </p>
                                  &nbsp; &nbsp;
                                  <p className="bold">$ {article.newPrice}</p>
                                  &nbsp; &nbsp;
                                  <p className="reduction-percentage bold">
                                    (-{article.reductionPercentage}%)
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="bold">$ {article.newPrice}</p>{" "}
                                </>
                              )}
                            </div>
                            {article.hasReduction && (
                              <>
                                <p className="reduction-percentage bold">
                                  REDUCED
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="group-btn">
                          <Link to="shop-details.html" className="btn-employer">
                            Ajouter au panier
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
                </div>

                <ul className="pagination-job padding">
                  {/* <li>
                    <Link to="#" onClick={navigateToFirstPage}>
                      <i className="icon-first_page"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={previousPage}>
                      <i className="icon-keyboard_arrow_left"></i>
                    </Link>
                  </li> */}
                  {/* Implement page number buttons here based on maxPage */}
                  {/* {Array.from({ length: maxPage }, (_, index) => (
                    <li
                      key={index}
                      className={actualPage === index + 1 ? "current" : ""}
                    >
                      <Link to="#" onClick={() => setActualPage(index + 1)}>
                        {index + 1}
                      </Link>
                    </li>
                  ))} */}
                  {/* <li>
                    <Link to="#" onClick={nextPage}>
                      <i className="icon-keyboard_arrow_right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={navigateToLastPage}>
                      <i className="icon-last_page"></i>
                    </Link>
                  </li> */}
                </ul>

                {/* <ul className="pagination-job padding">
                  <li>
                    <Link to="#">
                      <i className="icon-keyboard_arrow_left"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">1</Link>
                  </li>
                  <li className="current">
                    <Link to="#">2</Link>
                  </li>
                  <li>
                    <Link to="#">3</Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-keyboard_arrow_right"></i>
                    </Link>
                  </li>
                </ul> */}
              </TabPanel>
              <TabPanel className="inner">
                {filteredArticles.map((article) => (
                  <>
                    <div key={article.id} className="features-job style-3">
                      <div className="inner-box">
                        <div className="company">
                          <div className="logo-company">
                            <img
                              src={img_url + article.medias[0].path}
                              alt="MII"
                            />
                          </div>
                          <div className="box-content">
                            <h4>
                              <Link
                                to={`/store?categoryId=${article.category_id}`}
                              >
                                {article.category_name}
                              </Link>
                            </h4>
                            <h3>
                              <Link to={`/store?id=${article.id}`}>
                                {article.name}
                              </Link>
                              {/* <span className="icon-bolt"></span> */}
                            </h3>
                          </div>
                        </div>
                        <ul className="info">
                          <li>
                            <span className="icon-calendar"></span>
                            {moment(article.created_at).format("YYYY-MM-DD")}
                          </li>
                          {article.hasReduction && (
                            <>
                              <li className="reduction-percentage bold">
                                REDUCED
                              </li>
                            </>
                          )}
                        </ul>

                        <div className="salary">
                          <span className="icon-dolar1"></span>
                          {article.hasReduction ? (
                            <>
                              <p className="price-first alt-price bold">
                                ${article.price}
                              </p>
                              &nbsp; &nbsp;
                              <p className="bold">$ {article.newPrice}</p>
                              &nbsp; &nbsp;
                              <p className="reduction-percentage bold">
                                (-{article.reductionPercentage}%)
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="bold">$ {article.newPrice}</p>{" "}
                            </>
                          )}
                        </div>
                        <div className="group-btn">
                          {/* <span className="icon-heart"></span> */}
                          <button>Ajouter au panier</button>
                        </div>
                      </div>
                      <Link
                        to={`/store?id=${article.id}`}
                        className="jobtex-link-item"
                        tabIndex="0"
                      ></Link>
                    </div>
                  </>
                ))}

                <ul className="pagination-job padding">
                  {/* <li>
                    <Link to="#" onClick={navigateToFirstPage}>
                      <i className="icon-first_page"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={previousPage}>
                      <i className="icon-keyboard_arrow_left"></i>
                    </Link>
                  </li> */}
                  {/* Implement page number buttons here based on maxPage */}
                  {/* {Array.from({ length: maxPage }, (_, index) => (
                    <li
                      key={index}
                      className={actualPage === index + 1 ? "current" : ""}
                    >
                      <Link to="#" onClick={() => setActualPage(index + 1)}>
                        {index + 1}
                      </Link>
                    </li>
                  ))} */}
                  {/* <li>
                    <Link to="#" onClick={nextPage}>
                      <i className="icon-keyboard_arrow_right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={navigateToLastPage}>
                      <i className="icon-last_page"></i>
                    </Link>
                  </li> */}
                </ul>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default ArticlesListView;
