import axios from "axios";

export const getAllFaqs = () => {

    return new Promise((resolve, reject) => {
      axios
        .get('/get/faqs')
        .then( (response: any) => {
  
          resolve(response.data.data);
  
        })
        .catch((error: any) => {
          reject(error.response.data);
        });
    });
  };