import { Link } from "react-router-dom";
import { ArtCatNavItem } from "./ArtCatNavItem";

ArtCatMenuItem.propTypes = {};

export function ArtCatMenuItem({
  index,
  categories,
  handleNavigate = (path, e) => {},
}) {
  return (
    <li className={`menu-item menu-item-has-children sub${index}`}>
      <Link
        to="/store"
        key="/store"

        // onClick={(e) => handleNavigate("/store", e)}
      >
        Store
      </Link>
      <ul className="sub-menu st1">
        {categories.map((category, index) => (
          <ArtCatNavItem
            navIndex={index + 1}
            text={category.name}
            path={`/store?categoryId=${category.id}`}
            children={category.children ?? []}
            handleNavigate={handleNavigate}
          ></ArtCatNavItem>
        ))}
      </ul>
    </li>
  );
}
