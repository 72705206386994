import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataBlog from "../assets/fakeData/dataBlog";
import dataPartner from "../assets/fakeData/dataPartner";
import dataTestimonials from "../assets/fakeData/dataTestimonials";
import Blog01 from "../components/blog/Blog01";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import Header2 from "../components/header/Header2";
import ReviewJob from "../components/jobs/ReviewJob";
import Partner from "../components/partner";
import Term from "../components/term";
import Testimonials from "../components/testimonials";
import { GlobalStateContext } from "../context/AuthContext";
import { buildCategoryTree } from "../helpers/CategoryHelper";
import { getAllCategories } from "../services/CategoryService";
// Import Swiper React components

// Import Swiper styles
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import img1 from "../assets/images/services/1.jpeg";
import img2 from "../assets/images/services/2.jpeg";
import img3 from "../assets/images/services/3.jpeg";
import img4 from "../assets/images/services/4.jpeg";
import img5 from "../assets/images/services/5.jpeg";
import Banner01 from "../components/banner/Banner01";
import BlogArticles from "../components/blog/BlogArticles";
import HomeServices from "../components/services/home/HomeServices";
Home.propTypes = {};

// Use this serviceData array in your HomeServices component

function Home(props) {
  const { t } = useTranslation();
  const homeServicesData = [
    {
      title: t("marketStudy"),
      description: (
        <>
          <p>{t("marketStudyDescriptionBegin")}</p>
          <ul>
            <li>{t("marketStudyDescriptionElt1")}</li>
            <li>{t("marketStudyDescriptionElt2")}</li>
            <li>{t("marketStudyDescriptionElt3")}</li>
            <li>{t("marketStudyDescriptionElt4")}</li>
            <li>{t("marketStudyDescriptionElt5")}</li>
          </ul>
        </>
      ),
      imgSrc: img1,
      linkTo: "/etudes-de-marche",
    },
    {
      title: t("strategicMediaMonitoring"),
      description: (
        <>
          <p>{t("strategicMediaMonitoringDescription")}</p>
        </>
      ),
      imgSrc: img2,
      linkTo: "/strategic-competitive-intelligence",
    },
    {
      title: t("mediaIntelligence"),
      description: (
        <>
          <p>{t("mediaIntelligenceDescriptionBegin")}</p>
          <ul>
            <li>{t("mediaIntelligenceDescriptionElt1")}</li>
            <li>{t("mediaIntelligenceDescriptionElt2")}</li>
            <li>{t("mediaIntelligenceDescriptionElt3")}</li>
            <li>{t("mediaIntelligenceDescriptionElt4")}</li>
            <li>{t("mediaIntelligenceDescriptionElt5")}</li>
          </ul>
        </>
      ),
      imgSrc: img3,
      linkTo: "/service-intelligence-media",
    },
    {
      title: t("digitalResearch"),
      description: (
        <>
          <p>{t("digitalResearchDescriptionBegin")}</p>
          <ul>
            <li>{t("digitalResearchDescriptionElt1")}</li>
            <li>{t("digitalResearchDescriptionElt2")}</li>
            <li>{t("digitalResearchDescriptionElt3")}</li>
            <li>{t("digitalResearchDescriptionElt4")}</li>
            <li>{t("digitalResearchDescriptionElt5")}</li>
            <li>{t("digitalResearchDescriptionElt6")}</li>
          </ul>
        </>
      ),
      imgSrc: img4,
      linkTo: "/service-digital-research",
    },
    {
      title: t("accompanimentAndTrainings"),
      description: (
        <>
          <p>{t("accompanimentAndTrainingsDescription")}</p>
        </>
      ),
      imgSrc: img5,
      linkTo: "/service-accompagnements-et-formations",
    },
  ];

  let navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const { connected, user, afterLoginLight } = useContext(GlobalStateContext);
  const [isShowMobile, setShowMobile] = useState(false);

  const [categories, setCategories] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);
  const [articlesCategories, setArticlesCategories] = useState([]);

  useEffect(() => {
    const retrieveCategories = async () => {
      try {
        let response = await getAllCategories(); // Assuming this function returns a promise for categories
        const responseData = response ?? [];

        // Create a tree structure from the flat list of categories
        const categoryTree = buildCategoryTree(responseData);
        setCategories(categoryTree); // Set the categorized tree structure

        // Optionally filter and map categories for specific use cases
        const filteredArticles = categoryTree.filter(
          (cat) => cat.type === "ARTICLE"
        );
        const filteredNews = categoryTree.filter(
          (cat) => cat.type === "ACTUALITE"
        );

        // Assuming setArticlesCategories and setNewsCategories expect arrays of category trees
        setArticlesCategories(filteredArticles);
        setNewsCategories(filteredNews);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    retrieveCategories();
  }, []);
  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  useEffect(() => {
    /* const getPopup = document.querySelector(".wd-popup-form");
    setTimeout(() => {
      getPopup.classList.add("modal-menu--open");
    }, 3000);*/
  }, []);
  return (
    <>
      {/*<PopUpForm/>*/}

      {/* <div className="menu-mobile-popup">
          <div className="modal-menu__backdrop" onClick={handleMobile}></div>
          <div className="widget-filter">
            <div className="mobile-header">
              <div id="logo" className="logo">
                <Link to="/">
                  <img className="site-logo" src={logo} alt="Image"/>
                </Link>
              </div>
              <Link className="title-button-group" onClick={handleMobile}>
                <i className="icon-close"></i>
              </Link>
            </div>

            <Tabs className="tf-tab">
              <TabList className="menu-tab">
                <Tab className="user-tag">Menu</Tab>
                <Tab className="user-tag">Categories</Tab>
              </TabList>

              <div className="content-tab">
                <TabPanel className="header-ct-center menu-moblie animation-tab">
                  <div className="nav-wrap">
                    <nav className="main-nav mobile">
                      <ul id="menu-primary-menu" className="menu">
                        <li className="menu-item menu-item-has-children-mobile current-item">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("home");
                              }}
                          >
                            Home
                          </Link>
                          <Collapse isOpened={toggle.key === "home"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "home" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item menu-item-mobile current-item">
                                <Link to="/">Home Page 01 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v2">Home Page 02 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v3">Home Page 03 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v4">Home Page 04 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v5">Home Page 05 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v6">Home Page 06 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v7">Home Page 07 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v8">Home Page 08 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v9">Home Page 09 </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/home_v10">Home Page 10 </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>

                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("job");
                              }}
                          >
                            Find jobs
                          </Link>
                          <Collapse isOpened={toggle.key === "job"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "job" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v1">List Layout</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/job-grid">Grid Layout</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/job-list-sidebar">List Sidebar</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/job-grid-sidebar">Grid Sidebar</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v5">
                                  List Sidebar Fullwidth
                                </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v6">
                                  Grid Sidebar Fullwidth
                                </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v7">Top Map</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v8">Top Map Sidebar</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v9">Half Map - V1</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/joblist_v10">Half Map - V2</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/jobsingle_v1">Jobs Single - V1</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/jobsingle_v2">Jobs Single - V2</Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>

                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("employers");
                              }}
                          >
                            Employers
                          </Link>
                          <Collapse isOpened={toggle.key === "employers"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "employers" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item">
                                <Link to="/employers_v1">List Layout</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v2">Grid Layout</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v3">List Sidebar</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v4">Grid Sidebar</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v5">Full Width</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v6">Top Map</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employers_v7">Half Map</Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/news">
                                  Employers Single - V1
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employersingle_v2">
                                  Employers Single - V2
                                </Link>
                              </li>

                              <li className="menu-item">
                                <Link to="/employerreview">
                                  Employers Reviews
                                </Link>
                              </li>
                              <li className="menu-item">
                                <Link to="/employernotfound">
                                  Employers Not Found
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("candidate");
                              }}
                          >
                            Candidates
                          </Link>
                          <Collapse isOpened={toggle.key === "candidate"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "candidate" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v1">List Layout</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v2">Grid Layout</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v3">List Sidebar</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v4">Top Map</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v5">Half Map</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v6">No Available V1</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidates_v7">No Available V2</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidatesingle_v1">
                                  Candidate Single - V1
                                </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/candidatesingle_v2">
                                  Candidate Single - V2
                                </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/samplecv">Sample CV</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/samplecvslidebar">
                                  Sample CV Sidebar
                                </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/samplecvdetails">CV Details</Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("blog");
                              }}
                          >
                            Blog
                          </Link>
                          <Collapse isOpened={toggle.key === "blog"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "blog" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blog_v1">Blog List </Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blog_v2">Blog Grid</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blog_v3">Blog Masonry</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blogsingle_v1">Blog Details - V1</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blogsingle_v2">Blog Details - V2</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/blogsingle_v3">
                                  Blog Details Sidebar
                                </Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                              to="#"
                              className="iteam-menu"
                              onClick={() => {
                                handleToggle("pages");
                              }}
                          >
                            Pages
                          </Link>
                          <Collapse isOpened={toggle.key === "pages"}>
                            <ul
                                className="sub-menu-mobile"
                                style={{
                                  display: `${
                                      toggle.key === "pages" ? "block" : "none"
                                  }`,
                                }}
                            >
                              <li className="menu-item menu-item-mobile">
                                <Link to="/aboutus">About Us</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/faqs">FAQS</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/termsofuse">Terms Of Use</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/pricing">Pricing</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/shop">Shop List</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/shoppingcart">Shopping Cart</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/store">Shop Single</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/checkout">Checkout</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/login">Login</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/createaccount">Create Account</Link>
                              </li>
                              <li className="menu-item menu-item-mobile">
                                <Link to="/contactus">Contact Us</Link>
                              </li>
                            </ul>
                          </Collapse>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </TabPanel>

                <TabPanel className="categories animation-tab">
                  <div className="sub-categorie-mobile">
                    <ul className="pop-up">
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-1"></span>Design &
                          Creative
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-8"></span>Digital
                          Marketing
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-2"></span>Development &
                          IT
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-3"></span>Music & Audio
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-4"></span>Finance &
                          Accounting
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-5"></span>Programming &
                          Tech
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-6"></span>Video &
                          Animation
                        </Link>
                      </li>
                      <li className="categories-mobile">
                        <Link to="/jobsingle_v1">
                          <span className="icon-categorie-7"></span>Writing &
                          translation
                        </Link>
                      </li>
                    </ul>
                  </div>
                </TabPanel>
              </div>
            </Tabs>

            <div className="header-customize-item button">
              <Link to="/">Upload Resume</Link>
            </div>

            <div className="mobile-footer">
              <div className="icon-infor d-flex aln-center">
                <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
                </div>
                <div className="content">
                  <p>Need help? 24/7</p>
                  <h6>
                    <Link to="tel:0123456678">001-1234-88888</Link>
                  </h6>
                </div>
              </div>
              <div className="wd-social d-flex aln-center">
                <ul className="list-social d-flex aln-center">
                  <li>
                    <Link to="#">
                      <i className="icon-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-linkedin2"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-pinterest"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-instagram1"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>*/}

      <Header2
        clname="act1"
        handleMobile={handleMobile}
        articlesCategories={articlesCategories}
      />

      <Banner01 />
      <ReviewJob />
      <Blog01 data={dataBlog} className="news-section" />
      <br />
      <br />
      <BlogArticles />
      <br />
      <br />
      <HomeServices services={homeServicesData} navigates={navigateTo} />
      <Term />
      <Testimonials data={dataTestimonials} className="testimonials-section" />

      <Partner data={dataPartner} />

      <Footer />

      <Gotop />
    </>
  );
}

export default Home;
