import { loadTrianglesPreset } from "@tsparticles/preset-triangles";
import { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import React, { createContext, useEffect, useState } from "react";
import { buildCategoryTree } from "../helpers/CategoryHelper";
import { getCarts } from "../services/ArticleServices";
import { getAllCategories } from "../services/CategoryService";

const defaultGlobalState = {
  articlesCategories: [],
  newsCategories: [],
  connected: false,
  user: null,
  admin: null,
  particlesEngineInit: false,
  afterLogin: () => Promise,
  afterLoginLight: () => Promise,
  afterLogOut: () => Promise,
  logOut: () => {},
};
export const GlobalStateContext = createContext({ defaultGlobalState });

const AuthContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [myCart, setMyCart] = useState([]);
  const [articlesCategories, setArticlesCategories] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);

  const [particlesEngineInit, setParticlesEngineInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      await loadTrianglesPreset(engine);
      //await loadBasic(engine);
    }).then(() => {
      setParticlesEngineInit(true);
    });
  }, []);

  /*useEffect(() => {
    //setConnected(false);
    let local = localStorage.getItem("access_token");
    console.log("la data");
    console.log(local);
    if (local !== null && local !== undefined && local !== "undefined") {
      try {
        let result = localStorage.getItem(user);
        setUser(result);
        setConnected(true);
      } catch (error) {}
    } else {
      console.log("false false");
      setConnected(false);
    }
  }, [user]);
*/
  useEffect(() => {
    const retrieveCategories = async () => {
      try {
        let response = await getAllCategories();
        const responseData = response ?? [];
        const categoryTree = buildCategoryTree(responseData);
        const filteredArticles = categoryTree.filter(
          (cat) => cat.type === "ARTICLE"
        );
        const filteredNews = categoryTree.filter(
          (cat) => cat.type === "ACTUALITE"
        );
        setArticlesCategories(filteredArticles);
        setNewsCategories(filteredNews);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    retrieveCategories();
  }, []);

  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user_id");
    //setConnected(false);
    window.location.href = "/";
  };


  const afterLogin = async () => {
    if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
        try {
            setUser(localStorage.getItem("user"));
            //setConnected(true);
            localStorage.setItem('isLogged', true)

        } catch (error) {
        }
    }else{
        setConnected(false);
        localStorage.getItem('isLogged')

    }
};


const afterLoginLight = async () => {
  if (localStorage.getItem("access_token") ) {
      try {
          //setConnected(true);
          localStorage.setItem('isLogged', true)
      } catch (error) {
      }
  }
};


const afterLogOut = async () => {
  if (!localStorage.getItem("access_token") ) {
      try {
          //setConnected(false);
          localStorage.setItem('isLogged', false)

      } catch (error) {
      }
  }
};


  const afterAddToCard = async () => {
    if (
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token")
    ) {
      try {
        let data = await getCarts(user?.id);
        setMyCart(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <GlobalStateContext.Provider
      value={{
        articlesCategories: articlesCategories,
        newsCategories: newsCategories,
        connected,
        user,
        admin,
        particlesEngineInit: particlesEngineInit,
        afterLogin,
        afterLoginLight,
        afterLogOut,
        logOut,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default AuthContextProvider;
