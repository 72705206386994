import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLanguageSwitcher } from "./../../hooks/useLanguageSwitcher";

// Sprache-Flagge-Zuordnungen
const languageFlags = {
  en: "https://flagcdn.com/w20/gb.png",
  de: "https://flagcdn.com/w20/de.png",
  fr: "https://flagcdn.com/w20/fr.png",
  // Fügen Sie weitere Sprache-Flagge-Zuordnungen hier hinzu
};

const LanguageSelector = ({ toggleClassName = "", menuClassName = "" }) => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useLanguageSwitcher();

  // Ein Zustand, um die aktuell gewählte Sprache zu speichern
  const [currentLang, setCurrentLang] = useState(i18n.language);

  // Aktualisieren Sie den Zustand, wenn die Sprache geändert wird
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  return (
    <Dropdown className="react-dropdown select">
      <Dropdown.Toggle
        // variant="success"
        id="dropdown-basic"
        className={toggleClassName}
      >
        <img
          src={languageFlags[currentLang.substring(0, 2)]}
          width="20"
          alt={t(currentLang)}
        />
        &nbsp;
        {t(currentLang)}
      </Dropdown.Toggle>

      <Dropdown.Menu className={menuClassName}>
        {Object.entries(languageFlags).map(([code, flagUrl]) => (
          <Dropdown.Item key={code} onClick={() => changeLanguage(code)}>
            <img src={flagUrl} width="20" alt={t(code)} /> {t(code)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
