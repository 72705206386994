import React, { useContext } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { GlobalStateContext } from "../../context/AuthContext";
import "./style.css";
const groupedOptions = [
  {
    label: "Obst",
    options: [
      { value: "apfel", label: "Obst / Äpfel" },
      { value: "orange", label: "Obst / Orangen" },
    ],
  },
  {
    label: "Gemüse",
    options: [
      { value: "karotte", label: "Gemüse / Wurzelgemüse / Karotten" },
      { value: "spinat", label: "Gemüse / Blattgemüse / Spinat" },
    ],
  },
];

function MultiSelect() {
  const { connected, user, afterLoginLight, afterLogOut, articlesCategories } =
    useContext(GlobalStateContext);

  const convertDataToTreeSelectFormat = (data) => {
    return data.map((item) => ({
      label: item.name,
      value: item.id.toString(), // Die Bibliothek erwartet, dass die Werte als Strings vorliegen
      children: item.children
        ? convertDataToTreeSelectFormat(item.children)
        : [],
    }));
  };

  const treeData = convertDataToTreeSelectFormat(articlesCategories);
  return (
    <>
      <DropdownTreeSelect
        data={treeData}
        className="bootstrap-demo"
        onChange={(currentNode, selectedNodes) =>
          console.log("checked", currentNode, selectedNodes)
        }
        onAction={(node, action) => console.log("action", action, node)}
        onNodeToggle={(currentNode) => console.log("toggle", currentNode)}
      />
    </>
  );
}

export default MultiSelect;
