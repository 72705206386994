import axios from "axios";
//import * as FormData from 'form-data';
//import { request } from 'http';
//import { createReadStream } from 'fs';


export const me = () => {
    return new Promise((resolve, reject) => {
        axios.get('/me')
            .then((response) => {
                if (response.data) {
                    resolve(response);
                } else {
                    throw new Error('USER NOT FOUND');
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export const getAlert = () => {
    return new Promise((resolve, reject) => {
        axios.get('/get/alert')
            .then((response) => {
                if (response.data.data) {
                    resolve(response);
                } else {
                    throw new Error('ALERT NOT FOUND');
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export const forgot = (data) => {
    const emailFormData = new FormData();
    emailFormData.append("email", data.email);

    return new Promise((resolve, reject) => {
        axios.post('/forgetpassword', emailFormData)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export const resetP = (data) => {
    const resetFormData = new FormData();
    resetFormData.append("email", data.email);
    resetFormData.append("password", data.password);
    resetFormData.append("password_confirmation", data.password_confirmation);
    resetFormData.append("token", data.token);

    return new Promise((resolve, reject) => {
        axios.post('/resetpassword', resetFormData)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export function updateInfos(id, data, gender) {
    return new Promise((resolve, reject) => {
        axios.post(`/update/${id}`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function updateAvatar(id, image) {
  //const blob = new Blob([image]);
  //let url = URL.createObjectURL(image);
  //const readStream = createReadStream(url);
    const formData = new FormData();
    formData.append("avatar", image, "filename.jpg");
    formData.append("test", "test");

    return axios({
        method: 'post',
        url: `/admins/${id}/avatar`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
        // Gérer la réussite
    })
    .catch((response) => {
        // Gérer l'erreur
    });

/*   return new Promise((resolve, reject) => {
    axios
        .post('/admins/'+`${id}`+'/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then( (response )=> {
            resolve(response.data.data);
        })
        .catch((error) => {
            reject(error.response.data);
        });
}); */
}

export function changePassword(id, data) {
    const formData = new FormData();

    return new Promise((resolve, reject) => {
        axios.post(`/user/${id}/change/password`, data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getAllPurchases(id, pageId, nb_of_element) {
    const data = {numberElement: nb_of_element};
    const url = pageId !== undefined && pageId !== null ?
        `/user/${id}/purchases?page=${pageId}` : `/user/${id}/purchases`;

    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
            resolve(response.data.data);
        })
        .catch((error) => {
            reject(error.response.data);
        });
    });
}

export const getAllFaqs = () => {
    return new Promise((resolve, reject) => {
        axios.get('/get/faqs/type/FAQ')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export const getAllAgbs = () => {
    return new Promise((resolve, reject) => {
        axios.get('/get/faqs/type/AGB')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export function sendMail(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/send/user/mail`, data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function removeAvatarRequest(id, image) {
    return new Promise((resolve, reject) => {
        axios.put(`/user/${id}/avatar/remove`, image)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export const getAdmin = () => {
    return new Promise((resolve, reject) => {
        axios.get('/admin/contact')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};
