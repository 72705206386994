export function appyReductionOnArticle(article) {
  // Ensure null and undefined safety for necessary fields
  const price = article?.price ?? 0;
  const reductionPrice = article?.reduction_price ?? 0;
  const reductionType = article?.reduction_type;

  // Set default values
  article.hasReduction = false;
  article.reductionAmount = 0;
  article.reductionPercentage = 0;
  article.newPrice = price;

  if (reductionType === "AMOUNT") {
    // Calculate for reduction type "AMOUNT", provided reductionPrice is less than original price and greater than 0
    if (reductionPrice < price && reductionPrice > 0) {
      article.reductionAmount = reductionPrice;
      article.newPrice = price - reductionPrice;
      article.reductionPercentage = (reductionPrice / price) * 100;
      article.hasReduction = true;
    }
  } else if (reductionType === "PERCENTAGE") {
    // Calculate for reduction type "PERCENTAGE", provided reductionPrice is between 0 and 100 (exclusive)
    if (reductionPrice > 0 && reductionPrice < 100) {
      article.reductionPercentage = reductionPrice;
      article.newPrice = price - price * (reductionPrice / 100);
      article.reductionAmount = price - article.newPrice;
      article.hasReduction = true;
    }
  }

  // Handle cases where the reduction is 100% or equals the full price
  if (article.newPrice >= price || reductionPrice === 100) {
    article.hasReduction = false;
    article.reductionAmount = 0;
    article.reductionPercentage = 0;
    article.newPrice = price;
  }
  article.reductionPercentage = Math.round(article.reductionPercentage);

  return article;
}
