import Particles from "@tsparticles/react";
import React, { useContext, useMemo } from "react";
import { GlobalStateContext } from "../../../context/AuthContext";

const TriangleParticles = () => {
  const { particlesEngineInit } = useContext(GlobalStateContext);

  const options = useMemo(
    () => ({
      preset: "triangles",
      fullScreen: {
        enable: false,
      },
      background: {
        color: "#2D2D2D",
      },
      particles: {
        color: {
          value: "#B20101",
        },
        links: {
          color: "#B20101",
          distance: 150,
          enable: true,
          opacity: 0.6,
          width: 2,
        },
        number: {
          density: {
            enable: true,
            area: 800,
          },
          value: 300,
        },
        opacity: {
          value: 0.8,
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0.1,
          },
        },
        shape: {
          type: ["triangle", "square"],
          stroke: {
            width: 2,
            color: "#D6D0D0",
          },
        },
        size: {
          value: 5,
          random: { enable: true, minimumValue: 1 },
          anim: {
            enable: true,
            speed: 3,
            size_min: 0.1,
            sync: true,
          },
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          outMode: "bounce",
          attract: {
            enable: true,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: "repulse",
          },
          onClick: {
            enable: true,
            mode: "push",
          },
        },
        modes: {
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          attract: {
            distance: 200,
            duration: 0.2,
          },
        },
      },
      detectRetina: true,
    }),
    []
  );

  if (!particlesEngineInit) {
    return <></>;
  }

  return <Particles id="tsparticles" options={options} />;
};

export default TriangleParticles;
