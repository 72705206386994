import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import FormContentNews from "./FormContentNews";
import {img_url} from "../../constants";

BannerNews.propTypes = {
    
};

function BannerNews(props) {
    return (
        <section className="tf-slider parallax" style={{backgroundImage: `url(${img_url+props?.item?.medias[0].path})`}}>

                <div className="tf-container">
                <div className="row">
                    <div className="col-lg-8 col-md-8">
                        <FormContentNews title={props?.item?.name}
                                         description={props?.item?.short_description}
                                         //url={props}
                        />
                    </div>
                </div>
                </div>
                <div className="overlay"></div>
            </section>
    );
}

export default BannerNews;