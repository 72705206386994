import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./HomeServices.module.css";

const ServiceBox = ({ imgSrc, title, linkTo, children, navigates }) => {
  const [isTouched, setIsTouched] = useState(false);
  const handleTouch = () => {
    // Toggle the touched state
    setIsTouched(!isTouched);
  };
  return (
    <div
      className={`${styles.serviceBox} ${isTouched ? styles.touched : ""}`}
      onClick={handleTouch}
    >
      <div className={styles.boxHeader}>
        <div className={styles.imgWrapper}>
          <img src={imgSrc} alt={title} />
        </div>
      </div>
      <div className={styles.boxContent}>
        <h6 className={styles.boxTitle}>
          <Link to={linkTo}>{title}</Link>
        </h6>
        <div className={styles.boxContentDynamic}>{children}</div>
      </div>
    </div>
  );
};

const HomeServices = ({ services, navigates }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.innerIconboxSection}>
      <div className="tf-container">
        <div className={styles.titleIconbox}>
          <h1>{t("ourExpertiseDomains")}</h1>
        </div>
        <div className={styles.row}>
          <div className="col-lg-12">
            <div className={styles.groupCol3}>
              {services.map((service, index) => (
                <ServiceBox
                  key={index}
                  imgSrc={service.imgSrc}
                  title={service.title}
                  linkTo={service.linkTo}
                  navigates={navigates}
                >
                  <div className={styles.boxDescription}>
                    {service.description}
                  </div>
                  <button
                    onClick={() => navigates(service.linkTo)}
                    className={styles.viewMoreButton}
                  >
                    {t("seeMore")}
                    <span className={styles.iconArrowRight}>→</span>
                  </button>
                </ServiceBox>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;
