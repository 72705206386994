import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React from "react";

BlogNews.propTypes = {};

function BlogNews(props) {
  const { data } = props;
  const img_url = process.env.REACT_APP_BACKEND_SERVICE + "/media/";

  const isPdf = (news_item) => {
    const path = news_item?.path + '';
    console.log("path", path);
    if (path !== undefined) {
      return path.endsWith('.pdf');
    }
    return false;
  };

  const isDoc = (news_item) => {
    const path = news_item?.path + '';
    console.log("path", path);
    if (path !== undefined) {
      return path.endsWith('.doc') || path.endsWith('.docx');
    }
    return false;
  };

  function extractFilename(input) {
    if (input === undefined) return '';
    const parts = input.split('.');
    if (parts.length === 3) {
      let fileName = parts[1]+'';
      if(fileName.length>25)
      fileName = fileName.substring(0,25)+'...';
      return fileName;
    } else {
      return input;
    }
  }

  function extractFiletype(input) {
    if (input === undefined) return '';
    const parts = input.split('.');
    if (parts.length>1) {
      return (parts[parts.length-1]+'').toUpperCase();
    } else {
      return '';
    }
  }

  const isFileType = (filename, extensions) => extensions.some(ext => filename.endsWith(ext));

  const downloadZippedFiles = async (dataObject) => {
    const zip = new JSZip();
    const fileName = dataObject.name.replace(/[^a-zA-Z0-9]/g, '_') + ".zip"; // Ersetzt Sonderzeichen und Leerzeichen

    // Iteriere über die Dateien im 'files'-Array und füge sie zum ZIP-Archiv hinzu
    for (const file of dataObject.files) {
      const response = await fetch(file.path); // Hier müsstest du den tatsächlichen Pfad der Datei angeben
      const content = await response.blob(); // Erhalte den Inhalt als Blob
      zip.file(file.path.split('/').pop(), content); // Nutze nur den Dateinamen aus dem Pfad
    }

    // Generiere das ZIP-Archiv und starte den Download
    zip.generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, fileName); // Verwende den generierten Namen für das ZIP-Archiv
      });
  };

  return (
    <section>
      <div className="tf-container">
        <div className="blog wrap-blog stc">
          <div className="widget-side-bar">
            <div className="recent-posts">
              <div className="main-title">Downloads</div>
              <div className="cv-form-details stc po-sticky job-sg">
                {data?.files?.map((idx) => (
                  (isPdf(idx) && (
                    <div className="preview-cv mgt-32">
                      <div className="title">{extractFilename(idx?.path)}</div>
                      <div className="category">{extractFiletype(idx?.path)}</div>
                      <div className="icon icon-file-pdf"></div>
                    </div>
                  )) || (isDoc(idx) && (
                    <div className="preview-cv mgt-32">
                      <div className="title">{extractFilename(idx?.path)}</div>
                      <div className="category">{extractFiletype(idx?.path)}</div>
                      <div className="icon icon-file-doc"></div>
                    </div>
                  )) ||(true &&
                    
                    <div className="preview-cv mgt-32">
                    <div className="title">{extractFilename(idx?.path)}</div>
                    <div className="category">{extractFiletype(idx?.path)}</div>
                    {/* <div className="icon icon-file-doc"></div> */}
                  </div>
                    )
                ))}
                <a className="btn-dowload" onClick={() => downloadZippedFiles(data)}>
                  <i className="icon-download" onClick={() => downloadZippedFiles(data)}></i> Télécharger
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogNews;
