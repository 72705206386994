import React, { useEffect, useRef, useState } from "react";
import CheckmarkIcon from "./../../icons/CheckmarkIcon";

const ProgressiveRevealList = ({ items = [] }) => {
  const [visibleCount, setVisibleCount] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisibleCount(1); // Start revealing items when the list is in view
          observer.disconnect();
        }
      },
      {
        threshold: 0.1, // This threshold triggers when 10% of the list is visible
      }
    );

    observer.observe(listRef.current);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let timeoutId;
    if (visibleCount > 0 && visibleCount < items.length) {
      timeoutId = setTimeout(() => {
        setVisibleCount((count) => count + 1);
      }, 500); // Delay between reveals of each item
    }

    return () => clearTimeout(timeoutId);
  }, [visibleCount, items.length]);

  return (
    <ul className="wd-list-icon wow fadeInLeft" ref={listRef}>
      {items.map((item, index) => (
        <li
          key={index}
          style={{
            opacity: visibleCount > index ? 1 : 0,
            transform: visibleCount > index ? "none" : "translateY(20px)",
            transition: `opacity 1.25s ${index * 1.25}s, transform 1.25s ${
              index * 1.25
            }s`,
          }}
        >
          <CheckmarkIcon />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default ProgressiveRevealList;
