import axios from "axios";

export function getHomeNews() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/home/actualites')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getOneNews(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/actualites/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getRelatedNews(id) {
    console.log("detail")
    console.log(id)
    return new Promise((resolve, reject) => {
        axios
            .get(`/get/related/actualites/${id}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}