import axios from "axios";

export function getArticles(ass) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/get/articles/${ass}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getAllArticles() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/get/articles`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getHomeArticles() {
  return new Promise((resolve, reject) => {
    axios
      .get("/get/home/articles")
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getRelatedArticles(id) {
  console.log("detail");
  console.log(id);
  return new Promise((resolve, reject) => {
    axios
      .get(`/get/related/articles/${id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getSelectedArticles(ass) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/get/selected/articles/${ass}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getOneArticle(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/article/${id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getArticlesByCategoryAndAssociation(id, ass) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/articles/category/${id}/association/${ass}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getArticlesByFilter(
  keyWord,
  rangePrices,
  association,
  cat_ids,
  feature_value_ids,
  pagesize
) {
  let data = {
    key_word: keyWord,
    min_price: rangePrices[0],
    max_price: rangePrices[1],
    association: association,
    category_ids: cat_ids,
    feature_value_ids: feature_value_ids,
    pagesize: pagesize,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`/articles/filter`, data)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addToCart(user_id, variant_id, price, quantity) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();

    //formData.append("amount", (price * quantity).toString());
    formData.append("amount", price.toString());
    formData.append("quantity", quantity.toString());

    axios
      .post(`/add/variant/${variant_id}/cart/user/${user_id}`, formData)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getPaginatedArticles(id, ass, page, page_size) {
  if (page !== undefined) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/articles/category/${id}/association/${ass}/pagesize/${page_size}?page=${page}`
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/articles/category/${id}/association/${ass}/pagesize/${page_size}`
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }
}

export function purchaseCart(
  user_id,
  orderTotal,
  isShipped,
  currentUser,
  mothodActive,
  paypalEmail
) {
  let data = {
    user_id: user_id,
    order_total: orderTotal,
    is_shipped: isShipped,
    last_name: currentUser.last_name,
    first_name: currentUser.first_name,
    city: currentUser.city,
    country: currentUser.country,
    address: currentUser.address,
    email: currentUser.email,
    phone: currentUser.phone,
    payment_method: mothodActive,
    paypal_email: paypalEmail,
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`/payment`, { params: data })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getCarts(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/get/carts/user/${user_id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function clearCart(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/carts/user/${user_id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function removeFromCart(variant_id, user_id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/variant/${variant_id}/cart/user/${user_id}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function updateQuantity(user_id, variant_id, quantity) {
  return new Promise((resolve, reject) => {
    let formData = { quantity: quantity.toString() };

    axios
      .post(
        `/add/variant/${variant_id}/cart/user/${user_id}/quantity`,
        formData
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function download(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/invoice/purchase/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function createPaymentIntent(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/create/payment-intent`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
export function confirmPaymentIntent(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/confirm/payment-intent`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function retrievePaymentIntent(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/retrieve/payment-intent`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
