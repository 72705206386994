import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import avt from "../../assets/images/user/avatar/image-01.jpg";
import { ArtCatMenuItem } from "../../components/menu/categories/articles/ArtCatMenuItem";
import { GlobalStateContext } from "../../context/AuthContext";
import LanguageSelector from "../language/LanguageSelector";
Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const { t, i18n } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(null);

  const { connected, user, afterLoginLight, afterLogOut, articlesCategories } =
    useContext(GlobalStateContext);
  const [scroll, setScroll] = useState(0);
    const [connect, setConnect] = useState(null)
  const [userInfo, setUserInfo] = useState("");

  const handleDropdown = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
    const myUser= JSON.parse(localStorage.getItem('user'))//récuperer les infos de l'utilisateur
    if(myUser){
      setUserInfo(myUser.username)
    }else{
      setUserInfo('')
    }
    const isEnabled = localStorage.getItem('isLogged') === 'true';
    console.log(isEnabled)
    setConnect(isEnabled);

  }, []);

  const singOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("user");
    afterLogOut();
    window.location.replace(process.env.REACT_APP_SSO_SERVICE + "/logout");
  };

  return (
    <header
      id="header"
      className={`header header-default ${scroll ? "is-fixed is-small" : ""}`}
    >
      <div className="tf-container ct2">
        <div className="row">
          <div className="col-md-12">
            <div className="sticky-area-wrap">
              <div className="header-ct-left">
                <div id="logo" className="logo">
                  <Link to="/">
                    <img
                      className="site-logo"
                      id="trans-logo"
                      src={logo}
                      alt="Image"
                    />
                  </Link>
                </div>
                {/*                 <div className="categories">
                  <Link to="#">
                    <span className="icon-grid"></span>Categories
                  </Link>
                  <div className="sub-categorie">
                    <ul className="pop-up">
                      <li>
                        <Link to="#">
                          <span className="icon-categorie-1"></span>Design &
                          Creative
                        </Link>
                        <div className="group-menu-category">
                          <div className="menu left">
                            <h6>Top Categories</h6>
                            <ul>
                              <li>
                                <Link to="/joblist_v1">Design & Creative</Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">Digital marketing</Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">Development & IT</Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">Music & Audio</Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">
                                  Finance & Accounting
                                </Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">Programming & Tech</Link>
                              </li>
                              <li>
                                <Link to="/joblist_v1">video & Animation</Link>
                              </li>
                            </ul>
                          </div>
                          <div className="menu right">
                            <h6>Top Skills</h6>
                            <ul>
                              <li>
                                <Link to="/jobsingle_v1">Adobe Photoshop</Link>
                              </li>
                              <li>
                                <Link to="/jobsingle_v1">adobe XD</Link>
                              </li>
                              <li>
                                <Link to="/jobsingle_v1">
                                  Android Developer
                                </Link>
                              </li>
                              <li>
                                <Link to="/jobsingle_v1">Figma</Link>
                              </li>
                              <li>
                                <Link to="/jobsingle_v1">CSS, Html</Link>
                              </li>
                              <li>
                                <Link to="/jobsingle_v1">BA</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className="header-ct-center">
                <div className="nav-wrap">
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className={`menu ${clname}`}>
                      <li className="menu-item sub1">
                        <Link to="/">Home </Link>
                        {/*<div className="menu-bar">*/}
                        {/*  <ul className="sub-menu-bar">*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/">Home Page 01 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v2">Home Page 02 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v3">Home Page 03 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v4">Home Page 04 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v5">Home Page 05 </NavLink>*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}

                        {/*  <ul className="sub-menu-bar">*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v6">Home Page 06 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v7">Home Page 07 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v8">Home Page 08 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v9">Home Page 09 </NavLink>*/}
                        {/*    </li>*/}
                        {/*    <li className="menu-item">*/}
                        {/*      <NavLink to="/home_v10">Home Page 10 </NavLink>*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}
                        {/*</div>*/}
                      </li>
                      <ArtCatMenuItem
                        index={2}
                        categories={articlesCategories}
                        // handleNavigate={handleNavigate}
                      />
                      <li className="menu-item menu-item-has-children sub3">
                        <Link to="#">{t("support")}</Link>
                        <ul className="sub-menu st1">
                          <li className="nav-sub subnav2">
                            <NavLink to="/faqs">FAQs</NavLink>
                          </li>
                          <li className="nav-sub subnav2">
                            <NavLink to="/contactus">{t("contact")}</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children sub4">
                        <Link to="#">About</Link>
                        <ul className="sub-menu st1">
                          <li className="nav-sub subnav2">
                            <NavLink to="#">About us</NavLink>
                          </li>
                          <li className="nav-sub subnav2">
                            <NavLink to="#">Notre historique</NavLink>
                          </li>
                          <li className="nav-sub subnav1">
                            <Link to="#">
                              Services
                              <span className="icon-keyboard_arrow_right"></span>
                            </Link>
                            <ul className="nav-sub-menu">
                              <li className="nav-menu-item">
                                <NavLink to="/strategic-competitive-intelligence">
                                  Strategic competitive intelligence
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/etudes-de-marche">
                                  Etudes de marché
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/service-intelligence-media">
                                  Intelligence Média
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/service-digital-research">
                                  Digital Research
                                </NavLink>
                              </li>

                              <li className="nav-menu-item">
                                <NavLink to="/service-accompagnements-et-formations">
                                  Accompagnement et formations
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-sub subnav1">
                            <Link to="#">
                              Success stories
                              <span className="icon-keyboard_arrow_right"></span>
                            </Link>
                            <ul className="nav-sub-menu">
                              <li className="nav-menu-item">
                                <NavLink to="/success-story-western-union">
                                  Western Union
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/success-story-mamo-sarl">
                                  Mano SARL
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/success-story-mtn-cameroun">
                                  MTN Cameroun
                                </NavLink>
                              </li>
                              <li className="nav-menu-item">
                                <NavLink to="/success-story-nhpc">NHPC</NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-sub subnav2">
                            <NavLink to="/clients">Nos clients</NavLink>
                          </li>
                        </ul>
                      </li>

                      <li className="menu-item menu-item-has-children sub5">
                        <Link to="/trainings">{t("trainings")}</Link>
                        <ul className="sub-menu st1">
                          <li className="nav-sub subnav3">
                            <NavLink to="/trainings/courses">
                              {t("courses")}
                            </NavLink>
                          </li>
                          <li className="nav-sub subnav4">
                            <NavLink to="/trainings/teachers">
                              {t("teachers")}
                            </NavLink>
                          </li>
                          <li className="nav-sub subnav4">
                            <NavLink to="/trainings/programms">
                              {t("programms")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item sub6">
                        <Link to="/carriers">{t("carriers")}</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="header-ct-right">
                <LanguageSelector />
                {/*<div className="header-customize-item help">*/}
                {/*  <Link to="/termsofuse">*/}
                {/*    <span className="icon-help-circle"></span>*/}
                {/*  </Link>*/}
                {/*</div>*/}
                {/* <div className="header-customize-item bell">
                  <span className="icon-bell"></span>
                  <div className="sub-notification">
                    <div className="sub-notification-heading">
                      <div className="sub-notification-title">Notification</div>
                      <span>5 New</span>
                    </div>
                    <div className="sub-notification-content">
                      <div className="sub-notification-item icon-plus">
                        <div className="time">Last day</div>
                        <div className="content">
                          Your submit job
                          <span className="name">Graphic Design</span> is
                          <span className="status">Success</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">5 Day ago</div>
                        <div className="content">
                          A new application is submitted on your job
                          <span className="name">Graphic Design</span> by
                          <span className="name">Maverick Nguyen</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">5 Day ago</div>
                        <div className="content">
                          A new application is submitted on your job
                          <span className="name">Graphic Design</span> by
                          <span className="name">Maverick Nguyen</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">Last day</div>
                        <div className="content">
                          Your submit job{" "}
                          <span className="name">Graphic Design</span> is
                          <span className="status">Success</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">5 Day ago</div>
                        <div className="content">
                          A new application is submitted on your job
                          <span className="name">Graphic Design</span> by
                          <span className="name">Maverick Nguyen</span>
                        </div>
                      </div>
                    </div>
                    <div className="sub-notification-button">
                      <Link to="#">Read All</Link>
                    </div>
                  </div>
                </div>*/}
                {connect ? (
                  <>

                    <Link to="/myaccount">
                      <div className="header-customize-item account">
                        <img src={avt} alt="jobtex"/>
                        <div className="name">{userInfo}</div>
                      </div>
                    </Link>

                    <div
                      className="header-customize-item button bg-danger"
                      onClick={singOut}
                    >
                      <Link to="#">{t("logout")}</Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="header-customize-item button">
                      <Link
                        to={
                          process.env.REACT_APP_SSO_SERVICE +
                          "/login?redirect=" +
                          window.location.href
                        }
                      >
                        {t("login")}
                      </Link>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="header-customize-item button">
                      <Link
                        to={
                          process.env.REACT_APP_SSO_SERVICE +
                          "/register?redirect=http://" +
                          window.location.host
                        }
                      >
                        {t("register")}
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div className="nav-filter" onClick={handleMobile}>
                <div className="nav-mobile">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header2;
