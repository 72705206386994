import React, { useState } from "react";
import "./ScrollingText.css";

const ScrollingText = ({ text = "" }) => {
  const [animationDuration, setAnimationDuration] = useState("30s");

  //   useEffect(() => {
  //     // Dynamically adjust the animation speed based on the text length or container size
  //     const adjustDuration = () => {
  //       const duration = text.length / 10;
  //       setAnimationDuration(`${duration}s`);
  //     };

  //     adjustDuration();
  //     window.addEventListener("resize", adjustDuration);

  //     return () => {
  //       window.removeEventListener("resize", adjustDuration);
  //     };
  //   }, [text]);

  return (
    <div className="scrolling-text-container text-white bold">
      <div
        className="scroll-text text-white bold"
        style={{ animationDuration }}
      >
        {text}
      </div>
    </div>
  );
};

export default ScrollingText;
