import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import { img_url } from "../../constants";
import { getHomeNews } from "../../services/NewsService";

Blog01.propTypes = {};

function Blog01(props) {
  const { t } = useTranslation();
  const { data } = props;
  const { className } = props;

  const [news, setNews] = useState([]);

  useEffect(() => {
    const retrieveActualites = async () => {
      let response = await getHomeNews();
      setNews(response);
    };
    retrieveActualites();
  }, []);

  return (
    <section className={className}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2 style-4">
              <div className="group-title">
                <h1>{t("lastAnalyzationsTitle")}</h1>
                <p>{t("lastAnalyzationsSubTitle")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 wow fadeInUp">
            <Swiper
              modules={[Autoplay, Pagination]}
              spaceBetween={32}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={news && news.length > 2}
              slidesPerView={3}
              className="tes-slider"
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 32,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 32,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 32,
                },
              }}
            ></Swiper>
            {news?.map((idx) => (
              <SwiperSlide key={idx.id}>
                <div key={idx.id} className="col-lg-3 wow fadeInUp">
                  <div className="box-latest">
                    <div className="img-latest">
                      <a href={"/news?item=" + idx?.id}>
                        <img src={img_url + idx.medias[0].path} alt="Jobtex" />
                      </a>
                    </div>
                    <div className="box-content">
                      <div className="heading">
                        <Link
                          to={`/news?categoryId=${idx?.category_id}`}
                          className="tag"
                        >
                          {idx.category_name}
                        </Link>
                        <h3>
                          <a href={"/news?item=" + idx?.id}> {idx.name}</a>
                        </h3>
                      </div>
                      <ul className="date-post">
                        {/* <li>by {idx.category_name}</li> */}

                        <li>
                          <span className="icon-calendar"></span>
                          {moment(idx.created_at).format("YYYY-MM-DD")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog01;
