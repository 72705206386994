import React from "react";

FormContentNews.propTypes = {};

function FormContentNews(props) {
  return (
    <div className="content">
      <div className="heading">
        <h2 className="text-white">{props?.title ? props?.title :"Bienvenue sur Media Intelligence"}</h2>
        <p className="text-white">
            {props?.description  }
        </p>
      </div>
      {/*<div className="form-sl">*/}
      {/*  /!*<form action="/job-list-sidebar">*!/*/}
      {/*  <form action="#">*/}
      {/*    <div className="row-group-search home1">*/}
      {/*      <div className="form-group-1">*/}
      {/*        <input*/}
      {/*          type="text"*/}
      {/*          className="input-filter-search"*/}
      {/*          placeholder="Job title, key words or company"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="form-group-2">*/}
      {/*        <span className="icon-map-pin"></span>*/}
      {/*        <SelectLocation />*/}
      {/*      </div>*/}
      {/*      <div className="form-group-4">*/}
      {/*        <button type="submit" className="btn btn-find">*/}
      {/*          Find here*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </form>*/}
      {/*</div>*/}
      {/*<ul className="list-category text-white">*/}
      {/*  <li>*/}
      {/*    <Link to="/aboutus">Designer</Link>*/}
      {/*  </li>*/}
      {/*  <li className="current">*/}
      {/*    <Link to="/aboutus">Developer</Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/aboutus">Tester</Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/aboutus">Writing</Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/aboutus">Project Manager</Link>*/}
      {/*  </li>*/}
      {/*</ul>*/}
    </div>
  );
}

export default FormContentNews;
