import React, { useEffect, useState } from 'react';
import { changePassword } from '../../services/UserServices';
import axios from 'axios';
import { Link } from 'react-router-dom';
const ChangePassword = () => {

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);

  const [userInfo, setUserInfo] = useState({
    new_password: '',
    confirm_password: '',
    current_password:'',
});
  const [error, setError] = useState('')

  //pour récupérer l'id du user à travers le localstorage
  const uId= null;
  useEffect( ()=>{

      const fetchUserInfo = () => {

        try {
          uId= parseInt(localStorage.getItem("user_id"), 10); //convertir id en int 
          console.log(uId)
            

        } catch (error) {
          console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
        }
      };
  
      fetchUserInfo();
    }, []);


    const handleSubmit = async (event) => {
      event.preventDefault();

      //On vide le texte d'erreurs 
      setError('')
      // Récupérer les valeurs actuelles de tous les champs
      const updatedUserInfo = {
          current_password: userInfo.current_password,
          new_password: userInfo.new_password,
          confirm_password: userInfo.confirm_password,
          
      };
      console.log(updatedUserInfo);
      //validation des REGEX pour la validation   
      const passwordRegex= /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{10,}/;

      // Vérification des informations obligatoires
      if (!passwordRegex.test(updatedUserInfo.new_password)) {
        setError('Votre mot de passe doit contenir au moins une lettre, au moins un chiffre, au moins un caractère spécial (!@#$%) et comporter au moins 10 caractères.');
        return;
      }else if (!updatedUserInfo.new_password || !updatedUserInfo.confirm_password){
        setError('Veuillez remplir tous les champs obligatoires.')
      }
      else if (updatedUserInfo.new_password !== updatedUserInfo.confirm_password) {
        setError('Veuillez renseignez le même mot de passe');
        return;
      }
      else if (updatedUserInfo.new_password.trim()=== '' || updatedUserInfo.confirm_password.trim() === ''){
        setError('Veuillez remplir tous les champs obligatoires.')
      }
            
  
      try {
        //appel de la requete pour le changement de mot de passe 
        const response = await changePassword(uId, updatedUserInfo);
        console.log(response);

        //vérification de la réponse
        if(response.message.trim()==='Password successfully changed !' && response.status == 200){
          // Réinitialiser les champs et l'erreur après une soumission réussie
          setUserInfo({
            new_password: '',
            confirm_password:'',
            current_password: '',            
          })
          console.log('Données modifiées avec succès :', response.data);
        }
  

      } catch (error) {
        console.error('Erreur lors de la modification des données :', error);
        setError('Une erreur s\'est produite lors de la modification des données.');
      }
    };

    const handleChange = (event)=>{
      const {name, value} = event.target;
      setUserInfo(prevUserData => ({
          ...prevUserData,
          [name]: value
        }));

    }
  return (
    <>
    <h5>Change Password</h5>
    <section className="account-main">
      {error && <div className='nofi-form'><p style={{ color: 'red' }}>{error}</p></div>}
      <div className='wd-form-login'>
        <form onSubmit={handleSubmit}>
          <div className="ip">
              <label>Current Password <span>*</span></label>
              <div className="inputs-group">
              <input type={showPass ?"text" : "password"} name='password' value={userInfo.current_password} onChange={handleChange} />
                <Link
                  className={`password-addon ${
                      showPass ? "icon-eye" : "icon-eye-off"
                  }`}
                  id="password-addon"
                  onClick={() => setShowPass(!showPass)}
                    />
              </div>

          </div>

          <div className="ip">
              <label>New Password <span>*</span></label>
              <div className="inputs-group">
                <input type={showPass2 ?"text" : "password"} name='new_password' value={userInfo.new_password} onChange={handleChange} />
                <Link
                  className={`password-addon2 ${
                      showPass2 ? "icon-eye" : "icon-eye-off"
                  }`}
                  id="password-addon2"
                  onClick={() => setShowPass2(!showPass2)}
                    />
              </div>

          </div>

          <div className="ip">
              <label tyle={{fontWeight:'bolder'}}>Confirm New Password<span>*</span></label>
              <div className="inputs-group">
              <input type={showPass3 ?"text" : "password"} name='confirm_password' value={userInfo.confirm_password} onChange={handleChange} />
              <Link
                className={`password-addon3 ${
                    showPass3 ? "icon-eye" : "icon-eye-off"
                }`}
                id="password-addon3"
                onClick={() => setShowPass3(!showPass3)}
                  />
              </div>
          </div>

          <button type="submit">Change Password</button>
        </form>
      </div>

    </section>
    </>
  
  );
};

export default ChangePassword;
