import { NavLink } from "react-router-dom";
import { SubMenuItem } from "./SubMenuItem";

ArtCatNavItem.propTypes = {};

export function ArtCatNavItem({
  navIndex,
  text,
  path = "#",
  children = [],
  handleNavigate = (path, e) => {},
}) {
  return (
    <>
      {children.length === 0 ? (
        <li key={text} className={`nav-sub subnav${navIndex}`}>
          <NavLink
            to={path}

            // onClick={(e) => handleNavigate(path, e)}
          >
            {text}
          </NavLink>
        </li>
      ) : (
        <SubMenuItem
          navIndex={navIndex}
          text={text}
          path={path}
          children={children}
          handleNavigate={handleNavigate}
        />
      )}
    </>
  );
}
