import React, { useEffect, useState } from "react";

import Header from "../../components/header";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import dataEm from "../../assets/fakeData/dataEmployers";
import MesClient from "../../components/clients";
import Header2 from "../../components/header/Header2";

NosClients.propTypes = {};


function NosClients(props) {
    const [toggle, setToggle] = useState({
        key: "",
        status: false,
    });
    const [isShowMobile, setShowMobile] = useState(false);

    const handleToggle = (key) => {
        if (toggle.key === key) {
            setToggle({
                status: false,
            });
        } else {
            setToggle({
                status: true,
                key,
            });
        }
    };

    const handleMobile = () => {
        const getMobile = document.querySelector(".menu-mobile-popup");
        setShowMobile(!isShowMobile);
        !isShowMobile
            ? getMobile.classList.add("modal-menu--open")
            : getMobile.classList.remove("modal-menu--open");
    };

    useEffect(() => {
        const WOW = require("wowjs");
        window.wow = new WOW.WOW({
            live: false,
        });
        window.wow.init();
    }, []);


    return (
        <>

            <Header2 clname="act1" handleMobile={handleMobile} />

            <MesClient data={dataEm} className="employer-section" />

            <Footer />

            <Gotop />
        </>
    );
}

export default NosClients;
