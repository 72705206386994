import { Link, NavLink } from "react-router-dom";
SubMenuItem.propTypes = {};

export function SubMenuItem({
  navIndex,
  text,
  path = "#",
  children = [],
  handleNavigate = (path, e) => {},
}) {
  return (
    <li key={text} className={`nav-sub subnav${navIndex}`}>
      <Link
        to={path}

        // onClick={(e) => handleNavigate(path, e)}
      >
        {text}
        <span className="icon-keyboard_arrow_right"></span>
      </Link>
      <ul className="nav-sub-menu">
        {children.map((category, index) => (
          <li
            key={category.name}
            className={`nav-menu-item subitem${index + 1}`}
          >
            <NavLink
              to={`/store?categoryId=${category.id}`}
              // onClick={(e) => handleNavigate(path, e)}
            >
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
}
