import img1 from "../images/review/testimonials.jpg";

const dataTestimonials = [
  {
    id: 1,
    text: "“J'ai été extrêmement impressionné par le professionnalisme et l'expertise de cette entreprise. Leur approche personnalisée et leur attention aux détails ont vraiment fait la différence pour moi. Grâce à leurs services, j'ai pu atteindre mes objectifs commerciaux plus rapidement que prévu. Je recommande vivement cette entreprise à tous ceux qui recherchent des résultats concrets et une satisfaction client exceptionnelle”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 2,
    text: "“J'ai été extrêmement impressionné par le professionnalisme et l'expertise de cette entreprise. Leur approche personnalisée et leur attention aux détails ont vraiment fait la différence pour moi. Grâce à leurs services, j'ai pu atteindre mes objectifs commerciaux plus rapidement que prévu. Je recommande vivement cette entreprise à tous ceux qui recherchent des résultats concrets et une satisfaction client exceptionnelle”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 3,
    text: "“J'ai été extrêmement impressionné par le professionnalisme et l'expertise de cette entreprise. Leur approche personnalisée et leur attention aux détails ont vraiment fait la différence pour moi. Grâce à leurs services, j'ai pu atteindre mes objectifs commerciaux plus rapidement que prévu. Je recommande vivement cette entreprise à tous ceux qui recherchent des résultats concrets et une satisfaction client exceptionnelle”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 4,
    text: "“J'ai été extrêmement impressionné par le professionnalisme et l'expertise de cette entreprise. Leur approche personnalisée et leur attention aux détails ont vraiment fait la différence pour moi. Grâce à leurs services, j'ai pu atteindre mes objectifs commerciaux plus rapidement que prévu. Je recommande vivement cette entreprise à tous ceux qui recherchent des résultats concrets et une satisfaction client exceptionnelle”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
];

export default dataTestimonials;
