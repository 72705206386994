import React, { useEffect, useState } from "react";
import "./Typewriter.css";

const Typewriter = ({ text, className = "" }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text) {
      const timer = setInterval(() => {
        setCurrentIndex((oldIndex) => {
          if (oldIndex < text.length) {
            return oldIndex + 1;
          } else {
            clearInterval(timer);
            return oldIndex;
          }
        });
      }, 200); // Or any other suitable speed

      return () => clearInterval(timer);
    }
  }, [text]);

  const typewriterClass = `typewriter ${className}`;

  return (
    <div className={typewriterClass}>
      {text.substring(0, currentIndex)}
      <span className="cursor" />
    </div>
  );
};

export default Typewriter;
