import React, { useState } from "react";

import client1 from "../../assets/images/client1/1.png";
import client2 from "../../assets/images/client1/2.png";
import client3 from "../../assets/images/client1/3.png";
import client4 from "../../assets/images/client1/4.png";
import client5 from "../../assets/images/client1/5.png";
import client6 from "../../assets/images/client1/6.png";
import client7 from "../../assets/images/client1/7.jpeg";

import client8 from "../../assets/images/client2/1.png";
import client9 from "../../assets/images/client2/2.png";
import client10 from "../../assets/images/client2/3.png";
import client11 from "../../assets/images/client2/4.png";
import client12 from "../../assets/images/client2/5.png";
import client13 from "../../assets/images/client2/6.png";
import client14 from "../../assets/images/client2/7.png";
import client15 from "../../assets/images/client2/8.png";
import client16 from "../../assets/images/client2/9.jpeg";
import client17 from "../../assets/images/client2/10.png";
import client18 from "../../assets/images/client2/11.png";
import client19 from "../../assets/images/client2/12.png";
import client20 from "../../assets/images/client2/13.png";
import client21 from "../../assets/images/client2/14.png";
import client22 from "../../assets/images/client2/15.png";
import client23 from "../../assets/images/client2/16.png";
import client24 from "../../assets/images/client2/17.jpeg";

import client25 from "../../assets/images/client3/1.png";
import client26 from "../../assets/images/client3/2.png";
import client27 from "../../assets/images/client3/3.jpeg";
import client28 from "../../assets/images/client3/4.png";
import client29 from "../../assets/images/client3/5.png";
import client30 from "../../assets/images/client3/6.png";
import client31 from "../../assets/images/client3/7.png";
import client32 from "../../assets/images/client3/8.png";

import client33 from "../../assets/images/client4/1.jpeg";
import client34 from "../../assets/images/client4/2.png";
import client35 from "../../assets/images/client4/3.jpeg";
import client36 from "../../assets/images/client4/4.jpeg";
import client37 from "../../assets/images/client4/5.jpeg";
import client38 from "../../assets/images/client4/6.png";
import client39 from "../../assets/images/client4/7.jpeg";
import client40 from "../../assets/images/client4/8.png";
import client41 from "../../assets/images/client4/9.png";
import client42 from "../../assets/images/client4/10.png";



const CLIENTS_1 = [
    {
        id: "1",
        name: "",
        description: "",
        img: client1,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client2,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client3,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client4,
    },
    {
        id: "5",
        name: "",
        description: "",
        img: client5,
    },
    {
        id: "6",
        name: "",
        description: "",
        img: client6,
    },
    {
        id: "7",
        name: "",
        description: "",
        img: client7,
    }
];

const CLIENTS_2 = [
    {
        id: "1",
        name: "",
        description: "",
        img: client8,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client9,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client10,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client11,
    },
    {
        id: "5",
        name: "",
        description: "",
        img: client12,
    },
    {
        id: "6",
        name: "",
        description: "",
        img: client13,
    },
    {
        id: "7",
        name: "",
        description: "",
        img: client14,
    },
    {
        id: "1",
        name: "",
        description: "",
        img: client15,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client16,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client17,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client18,
    },
    {
        id: "5",
        name: "",
        description: "",
        img: client19,
    },
    {
        id: "6",
        name: "",
        description: "",
        img: client20,
    },
    {
        id: "7",
        name: "",
        description: "",
        img: client21,
    },
    {
        id: "1",
        name: "",
        description: "",
        img: client22,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client23,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client24,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client11,
    }
];

const CLIENTS_3 = [
    {
        id: "1",
        name: "",
        description: "",
        img: client25,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client26,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client27,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client28,
    },
    {
        id: "5",
        name: "",
        description: "",
        img: client29,
    },
    {
        id: "6",
        name: "",
        description: "",
        img: client30,
    },
    {
        id: "7",
        name: "",
        description: "",
        img: client31,
    },
    {
        id: "1",
        name: "",
        description: "",
        img: client32,
    },
];

const CLIENTS_4 = [
    {
        id: "2",
        name: "",
        description: "",
        img: client33,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client34,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client35,
    },
    {
        id: "5",
        name: "",
        description: "",
        img: client36,
    },
    {
        id: "6",
        name: "",
        description: "",
        img: client37,
    },
    {
        id: "7",
        name: "",
        description: "",
        img: client38,
    },
    {
        id: "1",
        name: "",
        description: "",
        img: client39,
    },
    {
        id: "2",
        name: "",
        description: "",
        img: client40,
    },
    {
        id: "3",
        name: "",
        description: "",
        img: client41,
    },
    {
        id: "4",
        name: "",
        description: "",
        img: client42,
    }
];


MesClient.propTypes = {};


function MesClient(props) {
  const [dataBlock] = useState({
    title: "Nos Clients et Partenaires",
    text: "",
  });

  const { data } = props;
  const { className } = props;

  return (
    <section className={className}>
      <div className="tf-container">
          <div className="wd-employer">
              <div className="tf-title">
                  <div className="group-title">
                      <h1>{dataBlock.title}</h1>
                      <p>{dataBlock.text}</p>
                  </div>
                  {/*<Button title="All Employers" link="/employers_v1" />*/}
              </div>
              <h6>Market intelligence</h6>
              <div className="row wow fadeInUp">
                  {CLIENTS_1.map((idx) => (
                      <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                          <div className="tf-iconbox style-3 cl3 stc2">
                              <div className="box-header">
                                  <div className="img1">
                                      <img src={idx.img} alt="img"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}
              </div>
              <br/>
              <h6>Etude de marché</h6>
              <div className="row wow fadeInUp">
                  {CLIENTS_2.map((idx) => (
                      <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                          <div className="tf-iconbox style-3 cl3 stc2">
                              <div className="box-header">
                                  <div className="img1">
                                      <img src={idx.img} alt="img"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}
              </div>

              <br/>
              <h6>Intelligence de Media</h6>
              <div className="row wow fadeInUp">
                  {CLIENTS_3.map((idx) => (
                      <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                          <div className="tf-iconbox style-3 cl3 stc2">
                              <div className="box-header">
                                  <div className="img1">
                                      <img src={idx.img} alt="img"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}
              </div>

              <br/>
              <h6>Digital business</h6>
              <div className="row wow fadeInUp">
                  {CLIENTS_4.map((idx) => (
                      <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                          <div className="tf-iconbox style-3 cl3 stc2">
                              <div className="box-header">
                                  <div className="img1">
                                      <img src={idx.img} alt="img"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}
              </div>
          </div>
      </div>
    </section>
  );
}

export default MesClient;
