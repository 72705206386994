import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

Footer.propTypes = {};

function Footer(props) {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="top-footer">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-2 col-md-4">
              <div className="footer-logo">
                <img
                  src={require("../../assets/images/logo.png")}
                  alt="images"
                />
              </div>
            </div>
            <div className="col-lg-10 col-md-8">
              <div className="wd-social d-flex aln-center">
                <span>{t("footerFollowUs")}</span>
                <ul className="list-social d-flex aln-center">
                  <li>
                    <Link
                      to="https://www.facebook.com/mediaintelligenceSARL"
                      target="_blank"
                    >
                      <i className="icon-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/media-intelligence-sarl/"
                      target="_blank"
                    >
                      <i className="icon-linkedin2"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://x.com/mediaintelli?s=09" target="_blank">
                      <i className="icon-twitter"></i>
                    </Link>
                  </li>
                  {/*<li><Link to="#"><i className="icon-pinterest"></i></Link></li>*/}
                  {/*<li><Link to="#"><i className="icon-instagram1"></i></Link></li>*/}
                  {/*<li><Link to="#"><i className="icon-youtube"></i></Link></li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-footer">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-cl-1">
                <div className="icon-infor d-flex aln-center">
                  <div className="icon">
                    <span className="icon-call-calling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </span>
                  </div>
                  <div className="content">
                    <p>{t("footerNeedHelp24_7")}</p>
                    <h6>
                      <Link to="tel:+237677431013">+237677431013</Link>
                    </h6>
                  </div>
                </div>
                <p>{t("footerMediaIntelligenceText")}</p>
                <div className="ft-icon">
                  <i className="icon-map-pin"></i> {t("footerAdress")}
                </div>
                <form action="#" id="subscribe-form">
                  <input
                    type="email"
                    placeholder="Your email address"
                    required=""
                    id="subscribe-email"
                  />
                  <button
                    className="tf-button"
                    type="submit"
                    id="subscribe-button"
                  >
                    <i className="icon-paper-plane-o"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-2 col-md-8 col-8">
              <div className="footer-cl-2">
                <h6 className="ft-title">{t("footerOurServices")}</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    <Link to="/strategic-competitive-intelligence">
                      {t("strategicMediaMonitoring")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/etudes-de-marche">{t("marketStudy")}</Link>
                  </li>
                  <li>
                    <Link to="/service-intelligence-media">
                      {t("mediaIntelligence")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-digital-research">
                      {" "}
                      {t("digitalResearch")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-accompagnements-et-formations">
                      {t("accompanimentAndTrainings")}
                    </Link>
                  </li>

                  {/*<li> <Link to="find-jobs-list.html">Jobs Style Grid</Link> </li>*/}
                  {/*<li> <Link to="employers-list.html">Employer Listing</Link> </li>*/}
                  {/*<li> <Link to="employers-grid-fullwidth.html">Employers Grid</Link> </li>*/}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-4">
              <div className="footer-cl-2">
                <h6 className="ft-title">
                  {t("footerAboutMediaIntelligence")}
                </h6>
                <ul className="navigation-menu-footer">
                  <li>
                    <Link to="/aboutus">{t("about")}</Link>
                  </li>
                  <li>
                    <Link to="/clients">{t("footerOurClients")}</Link>
                  </li>
                  <li>
                    <Link to="/success-stories">
                      {t("footerSuccessStories")}
                    </Link>
                  </li>
                  {/*<li> <Link to="find-jobs-list.html">Jobs Style Grid</Link> </li>*/}
                  {/*<li> <Link to="employers-list.html">Employer Listing</Link> </li>*/}
                  {/*<li> <Link to="employers-grid-fullwidth.html">Employers Grid</Link> </li>*/}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer-cl-3">
                <h6 className="ft-title">{t("footerHelp")}</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/contactus">{t("footerContactUs")}</Link>
                  </li>
                  {/*<li> <Link to="candidate-list-sidebar.html">Candidate Listing</Link> </li>*/}
                  {/*<li> <Link to="candidate-grid.html">Candidates Grid</Link> </li>*/}
                  {/*<li> <Link to="about-us.html">About us</Link> </li>*/}
                  {/*<li> <Link to="contact-us.html">Contact us </Link> </li>*/}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer-cl-4">
                <h6 className="ft-title">{t("footerTermsAndConditions")}</h6>
                <ul className="navigation-menu-footer">
                  <li>
                    <Link to="#">{t("footerGeneralCondition")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("footerImprint")}</Link>
                  </li>
                  {/*<li> <Link to="employers-grid-sidebar.html">Employers Grid</Link> </li>*/}
                  {/*<li> <Link to="find-jobs-list.html">Job Packages</Link> </li>*/}
                  {/*<li> <Link to="find-jobs-list.html">Jobs Listing</Link> </li>*/}
                  {/*<li> <Link to="find-jobs-grid.html">Jobs Style Grid</Link> </li>*/}
                </ul>
              </div>
            </div>
            {/*<div className="col-lg-2 col-md-4 col-6">*/}
            {/*    <div className="footer-cl-5">*/}
            {/*    <h6 className="ft-title">*/}
            {/*        Download App*/}
            {/*    </h6>*/}
            {/*    <ul className="ft-download">*/}
            {/*        <li> <Link to="#"><img src={require ('../../assets/images/review/btn3.png')} alt="images" /></Link></li>*/}
            {/*        <li> <Link to="#"><img src={require ('../../assets/images/review/btn4.png')} alt="images" /></Link></li>*/}
            {/*    </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="bt-left">
                <div className="copyright">
                  ©2024 Media Intelligence. All Rights Reserved.
                </div>
                {/*<div className="select-language">*/}
                {/*    <div className="dropdown" id="language">*/}
                {/*    <a className="btn-selector nolink input-form"><span><img src={require ('../../assets/images/review/flag.png')} alt="" /></span>*/}
                {/*        English</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className="menu-bottom d-flex aln-center">
                <li>
                  <Link to="#">{t("footerTermsOfServices")}</Link>
                </li>
                <li>
                  <Link to="#">{t("footerPrivacyPolicy")}</Link>
                </li>
                <li>
                  <Link to="#">{t("footerCookiePolicy")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
