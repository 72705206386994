/*import img1 from '../images/partners/Logo.png'
import img2 from '../images/partners/Logo-1.png'
import img3 from '../images/partners/Logo-2.png'
import img4 from '../images/partners/Logo-3.png'
import img5 from '../images/partners/Logo-4.png'
import img6 from '../images/partners/Logo-5.png'*/

import img1 from '../images/client1/1.png'
import img2 from '../images/client1/2.png'
import img3 from '../images/client1/3.png'
import img4 from '../images/client1/4.png'
import img5 from '../images/client1/5.png'
import img6 from '../images/client1/6.png'



const dataPartner = [
    {
        id: 1,
        img: img1
    },
    {
        id: 2,
        img: img2
    },
    {
        id: 3,
        img: img3
    },
    {
        id: 4,
        img: img4
    },
    {
        id: 5,
        img: img5
    },
    {
        id: 6,
        img: img6
    },
    {
        id: 7,
        img: img1
    },
    {
        id: 8,
        img: img2
    },
    {
        id: 9,
        img: img3
    },
    {
        id: 10,
        img: img4
    },
]

export default dataPartner;