import React from "react";
import { useTranslation } from "react-i18next";
import ProgressiveRevealList from "./../animations/reveal/ProgressiveRevealList";
import WidgetCounter from "./WidgetCounter";
import { Map } from '../animatedMap/Map';


ReviewJob.propTypes = {};

function ReviewJob(props) {
  const { t } = useTranslation();
  const listItems = [
    t("ourNetworkContent1"),
    t("ourNetworkContent2"),
    t("ourNetworkContent3"),
  ];
  return (
    <section className="review-job-section-two">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="wd-review-job contentbox1 padding">
              <h3>{t("ourNetworkTitle")}</h3>

              {/* <ProgressiveReveal
                items={[
                  "Search 2 millions of jobs and get the inside scoop on companies with employee reviews, personalized salary tools, and more.",
                ]}
                delay={3000}
                className={"wd-review-job contentbox1 padding"}
              /> */}
              {/* <p>
                Search millions of jobs and get the inside scoop on companies
                with employee reviews, personalized salary tools, and more.
              </p> */}
              <ProgressiveRevealList items={listItems} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <WidgetCounter />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReviewJob;
