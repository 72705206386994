import axios from "axios";

export const getCategory = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};

export function getCategoriesWithoutParents() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/categories/without/parents')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getAllCategories() {
    return new Promise((resolve, reject) => {
        axios
            .get('/categories')
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getDescentCategories(id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}/descent`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategoriesByAss(ass) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/selected/categories/association/${ass}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/selected/categories`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}

export function getNumberOfArticlesByAss(id, ass) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/number/articles/category/${id}/association/${ass}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}