import axios from "axios";

export function getJob(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/job/${id}`)
            .then((response  ) => {
                resolve(response.data.data);
            })
            .catch((error  ) => {
                reject(error.response.data);
            });
    });
}


export function getJobs() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/jobs')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}