import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from "../../assets/images/logo.png";
import { getAllPurchases } from '../../services/UserServices';

const MyOrders = () => {
  const [data, setData] = useState([]);
  const [showDetailsForIndex, setShowDetailsForIndex] = useState(null); // Stocke l'index de l'élément dont les détails sont affichés

  useEffect(() => {
    const getPurchases = async () => {
      try {
        const uId = parseInt(localStorage.getItem("user_id"), 10);
        const response = await getAllPurchases(uId, null, 10);
        if (response.data.length !== 0) {
          setData(response.data);
        } else {
          console.error('Erreur lors de la récupération des données:');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des achats:', error);
      }
    };

    getPurchases();
  }, []);

  const handleDetails = (index) => {
    setShowDetailsForIndex(index === showDetailsForIndex ? null : index); // Affiche ou masque les détails pour cet index
  };

  return (
    <section className="pricing-section-three">
      <h5>My Orders</h5>
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            {data.map((row, index) => (
              <div key={index}>
                <div className="employer-block">
                  <div className='order_info1'>
                    <h6>{row.transaction_id ? row.transaction_id : "jdoayydjkdd-557"}</h6>
                    <div className='payment_method'>
                      <p>Payment Method: </p>
                      <p>{row.payment_method ? row.payment_method : "Paypal"}</p>
                    </div>
                    <div className="order_info2">
                      <p>{row.created_at ? row.created_at : "2024/03/07 22:03:28"}</p>
                      <ul><li>{row.status ? row.status : "success"}</li></ul>
                    </div>
                  </div>
                  <div className='buttons_order'>
                    <button className="btn-employer" onClick={() => handleDetails(index)}>
                      Afficher Plus
                    </button>
                    <button className="btn-employer">
                      Facture
                    </button>
                  </div>
                </div>
                {showDetailsForIndex === index && (
                  <div className="employer-block-2">
                    <div className='order_info3'>
                      <img src={logo} alt="" />
                      <div className='order_info4'>
                        <p>{row.first_name ? row.first_name : "Test"}</p>
                        <p>Qty: {row.quantity ? row.quantity : "0"}</p>
                      </div>
                    </div>
                    <button className="btn-employer-2">
                      {row.amount ? "$" + row.amount : "$110"}
                    </button>
                  </div>
                )}
              </div>
            ))}
            {data.length === 0 && <p>empty Order</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyOrders;
