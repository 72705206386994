import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../components/breadcrumb";
import Header4 from "../components/header/Header4";
import JobSec1 from "../components/jobs/JobSec1";
import dataJobs from "../assets/fakeData/dataJobs";
import Footer from "../components/footer";
import FormSearch from "../components/formsearch";
import Gotop from "../components/gotop";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import Header2 from "../components/header/Header2";
import { getJobs } from "../services/JobServices";

Carriers.propTypes = {};

function Carriers(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const retrieveJobs = async () => {
      let data = await getJobs();
      let arr = Object.values(data);
      setJobs(arr);
    }
    retrieveJobs();
  }, []);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>

    <Header2 clname="actBlog5" handleMobile={handleMobile} />
      <Breadcrumb title="Find Jobs" className="breadcrumb-section" />

      <FormSearch />

      <JobSec1 data={jobs} />
      <Footer />
      <Gotop />
    </>
  );
}

export default Carriers;
