import React, { useContext } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import { validateOtp, checkConnect, getLastUrl } from "./services/AuthServices";

import i18n from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import ScrollToTop from "./ScrollToTop";
import Preloader from "./components/preloader";
import { GlobalStateContext } from "./context/AuthContext";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import Carriers from "./pages/Carriers";
import NosClients from "./pages/Clients/NosClients";
import ContactUs from "./pages/ContactUs";
import Faqs from "./pages/Faqs";
import Job from "./pages/Job";
import Login from "./pages/Login";
import Myaccount from "./pages/MyAccount";
import NewsPage from "./pages/NewsPage";
import Page404 from "./pages/Page404";
import Service1 from "./pages/Service1";
import Service3 from "./pages/Service3";
import Service4 from "./pages/Service4";
import Service5 from "./pages/Service5";
import Service6 from "./pages/Service6";
import Mtn from "./pages/Success-stories/MTN";
import ManoSarl from "./pages/Success-stories/Mano-sarl";
import Nhpc from "./pages/Success-stories/Nhpc";
import WesternUnion from "./pages/Success-stories/Western-union";
import Termsofuse from "./pages/Termsofuse";
import { ArticlesWrapper } from "./pages/articles/ArticlesWrapper";
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
};

function getCurrentLanguage() {
  const savedLanguage = localStorage.getItem("i18nextLng");

  if (savedLanguage && resources[savedLanguage]) {
    return savedLanguage;
  } else {
    const defaultLanguage = "en";
    localStorage.setItem("i18nextLng", defaultLanguage);
    return defaultLanguage;
  }
}

export const i18nConfig = i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(),
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/news", element: <NewsPage /> },
    { path: "/strategic-competitive-intelligence", element: <Service1 /> },
    { path: "/service-intelligence-media", element: <Service3 /> },
    { path: "/service-digital-research", element: <Service4 /> },
    { path: "/service-accompagnements-et-formations", element: <Service5 /> },
    { path: "/etudes-de-marche", element: <Service6 /> },
    { path: "/success-story-western-union", element: <WesternUnion /> },
    { path: "/success-story-mamo-sarl", element: <ManoSarl /> },
    { path: "/success-story-mtn-cameroun", element: <Mtn /> },
    { path: "/success-story-nhpc", element: <Nhpc /> },
    { path: "/clients", element: <NosClients /> },
    { path: "/store", element: <ArticlesWrapper /> },
    // { path: "/store/category", element: <ArticlesListPage /> },
    // { path: "/aboutus", element: <AboutUs /> },
    { path: "/faqs", element: <Faqs /> },
    { path: "/termsofuse", element: <Termsofuse /> },
    { path: "/login", element: <Login /> },
    { path: "/myaccount", element: <Myaccount /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/carriers", element: <Carriers /> },
    { path: "/job", element: <Job /> },
    { path: "*", element: <Page404 /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  //const { connected, user, afterLoginLight } = useContext(GlobalStateContext);
  const { afterLoginLight } = useContext(GlobalStateContext);
  const [connected, setConnected] = useState(false);
  const [user, setUser] = useState(null);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);


    const fetchLoginUrl = async () => {

      const queryParams = new URLSearchParams(window.location.search);
      let otp = queryParams.get("otp");
      const pathName = window.location.pathname;
      console.log(pathName);

      //check if the user still have a session
      const result = checkConnect()
      console.log(result);
      setUser(result)

      const connect = localStorage.getItem('isLogged')   
      console.log(connect)
      
      if(connect === true){
          window.history.pushState({}, '', pathName);
      }

        //if connected is not true it means the user is newly Logged then check the otp from request
        if (otp && otp.trim().length > 0 ){          
          //setLastUrl(microserviceUrl);
          // if (isAuthenticated()) {
          const response = await validateOtp(otp);
          if (response.success) {
            //removeLastUrl();
            localStorage.setItem("email", response.data.user_email);
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("access_token", JSON.stringify(response.data.token));
            afterLoginLight();
            window.history.pushState({}, '', pathName);
            //history.push(domain); 
            console.log('otp2')           
          }              
        }else {

          const urlParams = new URLSearchParams(window.location.search);

          // check if the parameter "otp" existe
          if (urlParams.has('otp')) {
            //if not otp generated, we are redirected to the sso 
            console.error('Erreur dans la géneration de OTP');
            //const ssoRedirection = getLastUrl();
            console.log(process.env.REACT_APP_SSO_SERVICE)
            console.log(window.location.host);
            console.log('otp3')    
            window.location.replace(process.env.REACT_APP_SSO_SERVICE + "/login?redirect="+window.location.href);

          }

        }      
      


      }

    fetchLoginUrl();
  }, []);

  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
