import React, { useState } from "react";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import { useEffect } from "react";
import {updateInfos} from "../../services/UserServices"

const MyAccountInfo = () =>{
    const [user, setUser]= useState(null);
    const [error, setError] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email:'',
        city: '',
        phone:'',
        address:'',
        //company:''
    });

    useEffect(() => {
        //récupère les infos dans le localstorage et envoi au userInfo state 
       const fetchUserInfo = () => {
 
         try {
 
             const mail= localStorage.getItem("email");
             const user= JSON.parse(localStorage.getItem("user"))
             console.log(user)
             const myUser= {
                 first_name: user.first_name,
                 last_name: user.last_name,
                 email: user.email,
                 city: user.city,
                 phone: user.phone,
                 address: user.address,
                 //company: user.company                
             }
             console.log(myUser)
             setUserInfo(myUser)
             
 
         } catch (error) {
           console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
         }
       };
   
       fetchUserInfo();
     }, []);
        
     
        const handleMouseEnter = () => {
          setIsHovered(true);
        };
      
        const handleMouseLeave = () => {
          setIsHovered(false);
        };
      
        //pour la gestion au click de l'image 
        const handleImageClick = (e) => {
          // Action à exécuter lorsqu'on clique sur l'image (ouverture du répertoire)
          const file = e.target.files[0];
          if (file) {
              const reader = new FileReader();
              reader.onload = function (e) {
                  setImageUrl(e.target.result);
              };
              reader.readAsDataURL(file);
          }
        };


        //gestion du click sur l'icone 
        const handleIconClick = () => {
            const fileInput= document.getElementById('fileInput');
            if (fileInput) {
                fileInput.click();
            }
          };
            


    //pour le click sur le formulaire
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Récupérer les valeurs actuelles de tous les champs
        const updatedUserInfo = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            email: userInfo.email,
            city: userInfo.city,
            phone: userInfo.phone,
            address: userInfo.address,
            //company: userInfo.company
        };
        console.log(updatedUserInfo);    
        // Vérification des informations obligatoires
        if (!updatedUserInfo.first_name || !updatedUserInfo.last_name || !updatedUserInfo.email || !updatedUserInfo.phone || !updatedUserInfo.address) {
          setError('Veuillez remplir tous les champs obligatoires.');
          return;
        }
    
        try {
          // Effectuer la requête HTTP pour modifier les données
          const uId= parseInt(localStorage.getItem("user_id"), 10); //convertir id en int 
          const response = await updateInfos( updatedUserInfo, uId);
          console.log(response);
    
          setError('');

        console.log('Données modifiées avec succès :', response.data);
        // Récupérer l'objet du localStorage
        const user = JSON.parse(localStorage.getItem('user'));

        // Effectuer les modifications nécessaires sur l'objet récupéré
        user.first_name = response.data.first_name;
        user.last_name = response.data.last_name;
        user.city = response.data.city;
        user.phone = response.data.phone;
        user.address = response.data.address;
        user.email = response.data.email;

        //  Enregistrer les informations dans le localstorage
        localStorage.setItem('user', JSON.stringify(user));

        } catch (error) {
          console.error('Erreur lors de la modification des données :', error);
          setError('Une erreur s\'est produite lors de la modification des données.');
        }
      };
      //pour la gestion du changement des textes dans les inputs
      const handleChange = (event)=>{
        const {name, value} = event.target;
        setUserInfo(prevUserData => ({
            ...prevUserData,
            [name]: value
          }));

      }
    
    return (

        <section className="sec-account-main">
            <h5>Account Information</h5>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="wd-form-login">
                <div className="account-main">
                    <div className="image-container">
                        <img
                            src={imageUrl ? imageUrl :  logo}
                            alt="Image"
                            className={`image ${isHovered ? 'hovered' : ''}`}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => document.getElementById('fileInput').click()}
                        />
                        {isHovered && (
                            <label className="overlay" htmlFor="fileInput">
                            <i className="icon-paper-plane-o icon" ></i>
                            </label>
                        )}          
                        <input type="file" id="fileInput" style={{ display: "none" }} onChange={handleImageClick} accept="image/*"/>
              
                    </div>

                    <form onSubmit={handleSubmit} >
                    <div className="ip">
                        <label>First Name <span>*</span></label>
                        <div className="inputs-group">
                        <input type="text" name= 'first_name'value={userInfo.first_name} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="ip">
                        <label>Last Name <span>*</span></label>
                        <div className="inputs-group">
                        <input type="text" name= 'last_name'value={userInfo.last_name} onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="ip">
                        <label>Email Adress <span>*</span></label>
                        <div className="inputs-group">
                        <input type="email" readOnly name="email" className="readonly" value={userInfo.email} />
                        </div>
                    </div>

                    <div className="ip">
                        <label>Address<span>*</span></label>
                        <div className="inputs-group">
                        <input type="text" name="address"  value={userInfo.address} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="ip">
                        <label tyle={{fontWeight:'bolder'}}>City<span>*</span></label>
                        <div className="inputs-group">
                        <input type="text" name="city" value={userInfo.city} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="ip">
                        <label tyle={{fontWeight:'bolder'}}>Phone Number <span>*</span></label>
                        <div className="inputs-group">
                        <input type="text" name="phone" value={userInfo.phone} onChange={handleChange}/>
                        </div>
                    </div>

                <button type="submit">Update Account</button>
                    </form>
                </div>
            </div>
        </section>
    ); 
}

export default MyAccountInfo;