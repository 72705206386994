import React from "react";
import Dropdown from "react-dropdown";
import RangeOne from "../../range-slider";
import RangeTwo from "../../range-slider/RangleTwo";
import MultiSelect from "../../select/MultiSelect";

const select1 = [
  { value: "s1", label: "All" },
  { value: "s2", label: "Sans reduction" },
  { value: "s3  ", label: "Avec reduction" },
];
const select2 = [
  { value: "s1", label: "On-site" },
  { value: "s2", label: "Remote" },
  { value: "s3  ", label: "Freelancer" },
];
const select3 = [
  { value: "s1", label: "All Job Types" },
  { value: "s2", label: "SoftWere" },
  { value: "s3  ", label: "Website" },
];
const select4 = [
  { value: "s1", label: "Posted Anytime " },
  { value: "s3", label: "Last week" },
  { value: "s4", label: "Last Month" },
  { value: "s5", label: "Last Quartal" },
  { value: "s6", label: "Last Year" },
];
const select5 = [
  { value: "s1", label: "All Seniority Levels " },
  { value: "s2", label: "Website" },
];
const select6 = [
  { value: "s1", label: "Company" },
  { value: "s2", label: "Website" },
];

function PopupArticlesFilter({ isShow, setIsShow, handlePopup }) {
  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <div className="sidebar-popup">
      <div className="modal-menu__backdrop" onClick={handlePopup}></div>
      <div className="widget-filter">
        <form onSubmit={handleSubmit}>
          <div className="group-form">
            <label className="title">Search Article</label>
            <div className="group-input search-ip">
              <button>
                <i className="icon-search"></i>
              </button>
              <input type="text" placeholder="Article title or keywords" />
            </div>
          </div>
          <div className="group-form">
            <label className="title">Reduction</label>
            <div className="group-input">
              <Dropdown
                options={select1}
                className="react-dropdown select2"
                value={select1[0]}
              />
            </div>
          </div>
          <RangeTwo title="Pix de reduction: " />
          <RangeOne miles="%" title="Pourcentage de reduction:" />

          <RangeTwo title="Prix: " />
          <div className="group-form">
            <label className="title">Categories</label>
            <div className="group-input">
              <MultiSelect />
            </div>
          </div>
          <div className="group-form">
            <label className="title">Posted Anytime</label>
            <div className="group-input">
              <Dropdown
                options={select4}
                className="react-dropdown select2"
                value={select4[0]}
              />
            </div>
          </div>
          <button type="submit">Search</button>
        </form>
      </div>
    </div>
  );
}

export default PopupArticlesFilter;
