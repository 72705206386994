import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { img_url } from "../../constants";
import { getRelatedNews } from "../../services/NewsService";

BlogCategory.propTypes = {};

function BlogCategory(props) {
  const { data } = props;
  const { className } = props;

  const [news, setNews] = useState([]);

  useEffect(() => {
    const retrieveRelatedActualites = async () => {
      let response = await getRelatedNews(props.newId);
      setNews(response);
    };
    retrieveRelatedActualites();
  }, []);

  return (
    <section className={className}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2 style-4">
              <div className="group-title">
                <h1>Autres actualités</h1>
                <p>Restez à jour avec les dernières actualités avec nous.</p>
              </div>
            </div>
          </div>
          {news?.map((idx) => (
            <div key={idx.id} className="col-lg-4 wow fadeInUp">
              <div className="box-latest">
                <div className="img-latest">
                  <a href={"/news?item=" + idx?.id}>
                    {" "}
                    <img src={img_url + idx.medias[0].path} alt="Jobtex" />
                  </a>
                </div>
                <div className="box-content">
                  <div className="heading">
                    <Link to="#" className="tag">
                      {idx.category_name}
                    </Link>
                    <h3>
                      <a href={"/news?item=" + idx?.id}> {idx.name}</a>
                    </h3>
                  </div>
                  <ul className="date-post">
                    {/* <li>by {idx.category_name}</li> */}
                    <li>
                      <span className="icon-calendar"></span>
                      {moment(idx.created_at).format("YYYY-MM-DD")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BlogCategory;
