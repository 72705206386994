import React from "react";
import { useLocation } from "react-router-dom";
import ArticlesListPage from "./ArticlesListPage";
import ArticlesPage from "./ArticlesPage";

export const ArticlesWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  if (id) {
    return <ArticlesPage />;
  }
  return <ArticlesListPage />;
};
