import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import phase1 from "../../assets/images/phases/phase1.jpeg";
import phase2 from "../../assets/images/phases/phase2.jpg";
import phase3 from "../../assets/images/phases/phase3.jpg";
import phase4 from "../../assets/images/phases/phase4.jpg";
import phase5 from "../../assets/images/phases/phase5.jpg";
import phase6 from "../../assets/images/phases/phase6.jpg";

function Term() {
  const { t } = useTranslation();
  return (
    <section className="term-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2 style-4">
              <div className="group-title">
                <h1>{t("methodologicalApproach")}</h1>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <Tabs className="row tf-tab">
          <div className="col-lg-2">
            <TabList className="menu-tab tab-term po-sticky">
              <Tab className="ct-tab">{t("step")} 1 </Tab>
              <Tab className="ct-tab">{t("step")} 2 </Tab>
              <Tab className="ct-tab">{t("step")} 3</Tab>
              <Tab className="ct-tab">{t("step")} 4</Tab>
              <Tab className="ct-tab">{t("step")} 5</Tab>
              <Tab className="ct-tab">{t("step")} 6</Tab>
            </TabList>
          </div>
          <div className="col-lg-10">
            <div className="content-tab mt-5">
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase1} alt="img" />
                  </div>

                  <div className="col-md-7">
                    <h6>1. {t("methodologicalApproachStep1Title")}</h6>
                    <p>{t("methodologicalApproachStep1Description1")}</p>
                    <p>{t("methodologicalApproachStep1Description2")}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase2} alt="img" />
                  </div>

                  <div className="col-md-7">
                    <h6>2. {t("methodologicalApproachStep2Title")}</h6>
                    <p>{t("methodologicalApproachStep2Description1")}</p>
                    <p>{t("methodologicalApproachStep2Description2")}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase3} alt="img" />
                  </div>

                  <div className="col-md-7">
                    <h6>3. {t("methodologicalApproachStep3Title")}</h6>
                    <p>{t("methodologicalApproachStep3Description1")}</p>
                    <p>{t("methodologicalApproachStep3Description2")}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase4} alt="img" />
                  </div>

                  <div className="col-md-7">
                    <h6>4. {t("methodologicalApproachStep4Title")}</h6>
                    <p>{t("methodologicalApproachStep4Description1")}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase5} alt="img" />
                  </div>

                  <div className="col-md-7">
                    <h6>5. {t("methodologicalApproachStep5Title")}</h6>
                    <p>{t("methodologicalApproachStep5Description1")}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="term-content animation-tab">
                <div className="row tf-tab">
                  <div className="col-md-5">
                    <img src={phase6} alt="img" />
                  </div>
                  <div className="col-md-7">
                    <h6>6. {t("methodologicalApproachStep6Title")}</h6>
                    <p>{t("methodologicalApproachStep6Description1")}</p>
                    <p>{t("methodologicalApproachStep6Description2")}</p>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </section>
  );
}

export default Term;
