import React from "react";
import { Link } from "react-router-dom";
Breadcrumb.propTypes = {};

function Breadcrumb({ title, className, elts = [] }) {
  return (
    <section className={`bg-f5 ${className ? className : ""}`}>
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title">
              <div className="widget-menu-link">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li>
                    <Link to="/store">Reports</Link>
                  </li>
                  <li>
                    <Link to="/store?categoryId=8">Reports by country</Link>
                  </li> */}

                  {elts.map((elt) => {
                    return (
                      <li key={elt.path}>
                        <Link to={elt.path}>{elt.title}</Link>
                      </li>
                    );
                  })}
                  {elts.length === 0 && (
                    <li>
                      <Link to="#">{title}</Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
