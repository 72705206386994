import React, { useEffect, useState } from 'react';
import MyAccountInfo from '../components/myaccount/MyAccountInfo';
import MyOrders from '../components/myaccount/MyOrders';
import ChangePassword from '../components/myaccount/ChangePassword';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header2 from '../components/header/Header2';
import axios
 from 'axios';
const Myaccount = () => {
  const [selectedOption, setSelectedOption] = useState('accountInfo');
  const [isShowMobile, setShowMobile] = useState(false);

 const [userInfo, setUserInfo] = useState({
  first_name: '',
  last_name: '',
  email:'',
  city: '',
  phone:'',
  address:'',
 })
 const [error, setError] = useState('');

 useEffect(()=>{
  try{

    const mail= localStorage.getItem("email");
    const user= JSON.parse(localStorage.getItem("user"))
    const myUser= {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        city: user.city,
        phone: user.phone,
        address: user.address,
        //company: user.company                
    }
    setUserInfo(myUser)    //setUserInfo(user)

  }catch(e){
    console.error('Erreur lors de la récupération des informations de l\'utilisateur:', e);
  }

},[]);
const handleMobile = () => {
  const getMobile = document.querySelector(".menu-mobile-popup");
  setShowMobile(!isShowMobile);
  !isShowMobile
    ? getMobile.classList.add("modal-menu--open")
    : getMobile.classList.remove("modal-menu--open");
};


  return (
    <>
    <Header2 clname="act1" handleMobile={handleMobile}/>
    <div className='tf-container'>
        <div className='row justify-content-lg-center'>
            <div className='col-lg-8'>
                <h4>Mon compte</h4>
                <div style={{ display: 'flex', marginTop: '20px'}}>
                    <p style={{fontWeight: 'bold'}}>{userInfo.first_name ? userInfo.first_name: 'Lionel Mambingo'},</p> <p>{userInfo.email ? userInfo.email:'lionelmambingo@gmail.com'}  {userInfo.city ? userInfo.city: 'Douala'}</p>
                </div>

                <hr  style={{marginTop: '4s5px', color: '#f1f1f1'}}/>                
                <Tabs className="job-article tf-tab single-job single-employer">
                <TabList className="menu-tab">
                  <Tab className="ct-tab" value="1">Account Info</Tab>
                  <Tab className="ct-tab"  value="2">My Orders</Tab>
                  <Tab className="ct-tab"  value="3">Change Password</Tab>
                </TabList>
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab" value="1"><MyAccountInfo /></TabPanel>
                  <TabPanel className="inner-content animation-tab" value="2"><MyOrders/></TabPanel>
                  <TabPanel className="inner-content animation-tab" value="3"><ChangePassword/></TabPanel>
                </div>
              </Tabs>
            </div>

        </div>

    </div>
    </>
  );
};

export default Myaccount;
